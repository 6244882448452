.session-expired-panel {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(255, 235, 65);
  border: 1px solid rgb(237, 218, 60);
  max-width: 600px;
  width: 90%;
  font-size: 13px;
  line-height: 2;
  font-weight: 700;
  text-align: center;
  color: black;
  position: fixed;
  z-index: $zindex-fixed;
  padding: 10px 20px 10px 10px;
  box-sizing: border-box;

  @media screen and (max-width: $breakpoint-sm - 1) {
    width: 100%;
  }
}

.session-expired-panel-close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 14px;
  height: 14px;
  cursor: pointer;

  &:hover {
    .icon {
      fill: $link-color;
      color: $link-color;
    }
  }

  .icon {
    width: 14px;
    height: 14px;
    display: block;
    fill: $body-inverse-color;
    color: $body-inverse-color;
  }
}

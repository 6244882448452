@import './TestGridItem/TestGridItem.scss';

.test-grid {
    border-collapse: collapse;
    border: none;
    list-style: none;
    margin: 0;
    padding: 0;

    &-header {
        display: flex;

        .cell {
            color: $label-color;
            font-weight: 600;
            font-size: $secondary-text-size;
            line-height: $secondary-line-height;
        }

        &-name {
            margin-left: 25px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            min-width: $nameWidth;
            max-width: $nameWidth;
            padding-right: $paddingItemRight;
        }

        &-time {
            padding-bottom: 10px;
            width: $timeWidth;
            padding-right: $paddingItemRight;
        }

        &-quantity {
            padding-bottom: 10px;
            width: $quantityWidth;
            padding-right: $paddingItemRight;
        }

        &-language {
            padding-bottom: 10px;
            width: $languageWidth;
            padding-right: $paddingItemRight;
        }

        &-month-count {
            padding-bottom: 10px;
            width: $monthCountWidth;
            padding-right: $paddingItemRight;
        }

        &-finish-date {
            padding-bottom: 10px;
            width: $finishDateWidth;
            padding-right: $paddingItemRight;
        }

        &-test-area {
            padding-bottom: 10px;
            width: $testAreaWidth;
            padding-right: $paddingItemRight;
        }

        &-product-group {
            padding-bottom: 10px;
            width: $testAreaWidth;
            padding-right: $paddingItemRight;
        }

        &-active-version {
            padding-bottom: 10px;
            width: $activeVersionWidth;
            padding-right: $paddingItemRight;
        }

        &-creation-date {
            padding-bottom: 10px;
            width: $creationDateWidth;
            padding-right: $paddingItemRight;
        }

        &-test-type {
            padding-bottom: 10px;
            width: $testTypeWidth;
            padding-right: $paddingItemRight;
        }
    }
}

.details-grid {
  width: 700px;
  margin: 50px 0 30px 0;

  &-block {
    display: flex;
    width: 100%;
  }

  &-header {
    display: flex;
    color: $label-color;
    font-weight: 600;
    font-size: $secondary-text-size;
    padding-bottom: 10px;

    &-cell {
      width: 100%;
      margin: 0 15px 0 0;
      font-size: 14px;
    }
  }

  &-cell {
    width: 100%;
    margin-right: 15px;
    font-size: 18px;
  }

  &-row {
    display: flex;
    height: 65px;
    border-top: 1px solid $input-border-color;
    align-items: center;
  }
}

.icon {
    color: $icon-color;
    fill: currentColor;
    stroke: none;
    width: 1em;
    height: 1em;

    &:hover {
        color: $icon-hover-color;
    }

    &-logout {
        font-size: 24px;
    }

    &-menu-test {
        stroke: currentColor;
        fill: none;
    }

    &-menu-stats {
        stroke: none;
        fill: currentColor;
    }

    &-menu-cert {
        stroke: currentColor;
        fill: none;
    }

    &-menu-group {
        stroke: currentColor;
        fill: none;
    }

    &-menu-journal {
        color: transparent;
        stroke: $body-color;
        fill: none;

        .menu-nav-link:hover & {
            stroke: $link-color;
        }

        .active & {
            stroke: $link-color;
        }
    }

    &-angle-down {
        stroke: currentColor;
        fill: none;
    }

    &-menu-admins {
        fill: none;
    }

    &-not-found-svg {
        width: 509px;
        height: 588px;

        @media screen and (max-width: $breakpoint-md) {
            width: 409px;
            height: 488px;
        }

        @media screen and (max-width: $breakpoint-sm) {
            background-image: none;
            width: 309px;
            height: 388px;
        }
    }

    &-check-mark {
        width: 24px;
        height: 24px;
        fill: none;
    }

    &-empty-list {
        height: 41px;
        width: 41px;
    }

    &-eye {
        width: 80px;
        height: 61px;
    }

    &-twitter-logo {
        width: 20px;
        height: 20px;
    }

    &-linkedin-logo {
        width: 20px;
        height: 20px;
    }

    &-facebook-logo {
        width: 20px;
        height: 20px;
    }

    &-vk-logo {
        width: 20px;
        height: 20px;
    }

    &-icon-expand {
        font-size: 32px;
    }

    &-no-logged-in {
        display: block;
        width: 39px;
        height: 39px;
        fill: $primary-bg;
    }

    &-message {
        color: transparent;
    }
}

@import '../../styles/colors';

.checkbox_new {
    min-width: 26px;
    padding: 5px 5px 10px 10px;

    &.disabled {
        .checkbox_new_text {
            cursor: default !important;
        }
    }
}

.checkbox_new_control {
    @include hidden-input();

    + .checkbox_new_text {
        position: relative;
        display: block;
        line-height: 1.2;
        box-sizing: border-box;
        cursor: pointer;
        padding-left: 38px;
        padding-bottom: 5px;

        &::before {
            content: '';
            position: absolute;
            width: 26px;
            height: 26px;
            left: -5px;
            background-color: transparent;
            border: 1px solid $input-border-color;
        }
    }
}

.checkbox_new_text {
    font-size: 14px;
    span {
        margin-left: 40px;
        line-height: 28px;
    }
}

.checkbox_new_control:checked {
    + .checkbox_new_text {
        &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 9px;
            top: 5px;
            left: 2px;
            transform: rotate(-45deg);
            border-bottom: solid 2px $link-color;
            border-left: solid 2px $link-color;
        }
    }
}

.logout {
  display: block;
  margin-left: 60px;
  margin-top: 10px;

  @media screen and (min-width: $breakpoint-sm) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 24px;
    height: 50px;
    margin: 0;
  }

  .icon {
    display: none;

    @media screen and (min-width: $breakpoint-sm) {
      display: block;
    }
  }

  &::before {
    display: block;
    content: attr(title);
    opacity: .27;
    color: $body-color;
    text-transform: capitalize;
    font-size: 20px;

    @media screen and (min-width: $breakpoint-sm) {
      display: none;
    }
  }
}
.grid {
    width: 100%;
    border-collapse: collapse;
    border: none;
    list-style: none;
    padding: 0;
    word-break: break-word;

    &-header {
        display: flex;
        font-weight: 600;
        font-size: $secondary-text-size;
        color: $label-color;
        line-height: $secondary-line-height;
        align-items: center;
        justify-content: space-between;
        padding: 8px 25px;
        white-space: pre-wrap;
        word-break: keep-all;
    }

    &-row {
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: space-between;
        padding: 20px 25px;
        border-top: 1px solid $grid-border-color;

        &:last-child {
            border-bottom: 1px solid $grid-border-color;
        }
    }

    .space-between {
        display: flex;
        justify-content: space-between;
        margin: 0;
    }

    .cell {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-right: 5px;
        margin: 0;

        &-stretch {
            align-items: stretch;
        }

        &-start {
            align-items: start;
        }

        &-center {
            align-items: center;
        }

        &-end {
            align-items: end;
        }

        &-actions {
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }

        .details {
            margin-top: 2px;
            color: $link-color;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            transition: color 0.3s ease;

            &:hover {
                transition: color 0.3s ease;
                color: $body-color;
            }
        }

        .link:not(:last-child) {
            padding-right: 30px;
        }

        &-button-group {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;

            .btn {
                flex: 1 0 45%;

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }
}

@import "./info-result/InfoResult";
@import "TestResultAnimation";

$breakpoint-tablet-landscape: 1023px;

.test-result-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $breakpoint-tablet-landscape) {
    display: block;
  }
}

.test-result-value {
  order: 2;
}

.result-block {
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  max-width: 385px;
  max-height: 502px;

  @media screen and (max-width: $breakpoint-tablet-landscape) {
    margin: 0 auto 10px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    svg {
      width: 100%;
      height: auto;
    }
  }

  &.done {
    .without-distinction {
      display: none;
    }

    .result-text-name {
      display: none;
    }
  }

  .result-information {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: -30px auto;
    text-align: center;
  }

  .result-text {
    font-size: 17px;
    font-weight: 300;
    line-height: $primary-line-height;
  }

  .result-value {
    font-size: $main-header-text-size;
    font-weight: 900;
    line-height: $main-header-line-height;

    @media screen and (max-width: $breakpoint-sm) {
      font-size: 32px;
    }
  }

  .result-value-total {
    color: $text-sub-color;
  }
}

@import './CategoryGridItem/CategoryGridItem.scss';

.category-grid {
    width: 100%;
    border-collapse: collapse;
    border: none;
    list-style: none;
    margin: 0;
    padding: 0;

    &-header {
        display: flex;
        justify-content: space-between;

        &-name {
            padding-left: 95px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            color: $label-color;
            font-weight: 600;
            font-size: $secondary-text-size;
            line-height: $secondary-line-height;
            cursor: pointer;
        }

        &-quantity {
            color: $label-color;
            font-weight: 600;
            font-size: $secondary-text-size;
            line-height: $secondary-line-height;
            padding-right: 165px;
            padding-bottom: 10px;
        }
    }
}

@import '../../styles/variables';

.landing-page-main-container {
    overflow-x: hidden;
}

.landing-page {
    display: flex;
    flex-direction: column;
    background: url('../../images/landing/bg.jpg') center top / cover no-repeat;
    position: relative;
    font-family: 'KasperskySans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;

    @media (max-width: $breakpoint-sm) {
        background-image: url('../../images/landing/bg_m.jpg');
    }

    &__wrap {
        padding-bottom: 32px;

        @media (min-width: $breakpoint-md) {
            padding-bottom: 64px;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        margin-top: 0;
        margin-bottom: 15px;
        z-index: 20;
        position: relative;

        @media (min-width: $breakpoint-sm) {
            font-size: 36px;
            line-height: 43px;
        }

        @media (min-width: $breakpoint-md) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 34px;
        }

        @media (min-width: $breakpoint-lg) {
            font-size: 48px;
            line-height: 58px;
            margin-bottom: 28px;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 20px;

        @media (min-width: $breakpoint-sm) {
            margin-bottom: 15px;
        }

        @media (min-width: $breakpoint-md) {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 35px;
        }

        @media (min-width: $breakpoint-lg) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 15px;
        }
    }

    &__bg-image {
        position: absolute;
        pointer-events: none;

        &--top-left {
            width: 331px;
            top: -85px;
            left: -60px;

            @media (min-width: $breakpoint-sm) {
                width: 657px;
                left: -95px;
                top: -355px;
            }

            @media (min-width: $breakpoint-sm) {
                width: 657px;
                left: -95px;
                top: -355px;
            }

            @media (min-width: $breakpoint-md) {
                width: 810px;
                left: -160px;
                top: -450px;
            }

            @media (min-width: $breakpoint-lg) {
                left: auto;
                right: 50%;
                margin-right: 32px;
            }
        }

        &--top-right {
            width: 408px;
            top: -1px;
            right: -250px;

            @media (min-width: $breakpoint-xs) {
                right: -160px;
            }

            @media (min-width: $breakpoint-sm) {
                width: 546px;
                top: -35px;
                right: -130px;
            }

            @media (min-width: $breakpoint-sm) {
                width: 546px;
                top: -35px;
                right: -130px;
            }

            @media (min-width: $breakpoint-md) {
                width: 774px;
                top: -164px;
                right: -203px;
            }

            @media (min-width: $breakpoint-lg) {
                width: 838px;
                top: -212px;
                right: auto;
                left: 50%;
                margin-left: -23px;
            }
        }
    }
}

.landing-cert-info {
    padding-top: 32px;
    margin-bottom: 44px;

    @media (min-width: $breakpoint-sm) {
        margin-bottom: 65px;
    }

    @media (min-width: $breakpoint-md) {
        padding-top: 80px;
        margin-bottom: 80px;
    }

    @media (min-width: $breakpoint-lg) {
        padding-top: 100px;
        padding-left: 70px;
        margin-bottom: 75px;
    }

    &__top {
        max-width: 270px;
        margin-bottom: 46px;

        @media (min-width: $breakpoint-sm) {
            max-width: 440px;
            margin-bottom: 135px;
            min-height: 212px;
        }

        @media (min-width: $breakpoint-md) {
            max-width: 50%;
            min-height: 254px;
        }

        @media (min-width: $breakpoint-lg) {
            max-width: 560px;
            min-height: 274px;
        }
    }

    &__text {
        line-height: 17px;

        @media (min-width: $breakpoint-sm) {
            max-width: 315px;
        }

        @media (min-width: $breakpoint-md) {
            max-width: 400px;
            font-size: 16px;
            line-height: 19px;
        }

        @media (min-width: $breakpoint-lg) {
            max-width: none;
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__bottom {
        @media (min-width: $breakpoint-sm) {
            display: flex;
            justify-content: space-between;
        }

        .landing-page__subtitle {
            margin-bottom: 70px;

            @media (min-width: $breakpoint-sm) {
                margin-bottom: 0;
                width: 195px;
            }

            @media (min-width: $breakpoint-md) {
                width: 350px;
            }
        }
    }

    &__list {
        display: flex;
        padding-left: 10px;

        @media (min-width: $breakpoint-sm) {
            width: 530px;
            padding-left: 0;
            justify-content: space-between;
            margin-top: 40px;
        }

        @media (min-width: $breakpoint-md) {
            width: 600px;
            margin-top: 30px;
        }

        @media (min-width: $breakpoint-lg) {
            width: 710px;
            margin-top: 5px;
        }
    }

    &__item {
        flex: 0 0 auto;
        width: 33.33333333%;
        max-width: 149px;
        font-size: 11px;
        line-height: 1.2;
        position: relative;

        span {
            position: relative;
        }

        @media (min-width: $breakpoint-xs) {
            font-size: 14px;
        }

        @media (max-width: 479px) {
            padding-right: 5px;
        }

        @media (min-width: $breakpoint-md) {
            max-width: 177px;
            font-size: 16px;
            line-height: 19px;
        }

        @media (min-width: $breakpoint-lg) {
            max-width: 230px;
            font-size: 20px;
            line-height: 24px;
        }

        &::before {
            content: '';
            position: absolute;
        }

        &:nth-child(1) {
            @media (max-width: 479px) {
                max-width: 110px;
            }

            @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm - 1) {
                max-width: 139px;
            }

            &::before {
                width: 105px;
                height: 100px;
                background: url('../../images/landing/block_1.svg') center center / contain no-repeat;
                top: -30px;
                left: -15px;

                @media (min-width: $breakpoint-xs) {
                    width: 135px;
                    height: 121px;
                    top: -43px;
                    left: -19px;
                }

                @media (min-width: $breakpoint-sm) {
                    top: -51px;
                    left: -30px;
                }
            }
        }

        &:nth-child(2)::before {
            width: 96px;
            height: 102px;
            background: url('../../images/landing/block_2.svg') center center / contain no-repeat;
            top: -33px;
            left: -10px;

            @media (min-width: $breakpoint-xs) {
                width: 116px;
                height: 102px;
                top: -40px;
                left: -13px;
            }

            @media (min-width: $breakpoint-sm) {
                top: -38px;
                left: -29px;
            }
        }

        &:nth-child(3)::before {
            width: 95px;
            height: 94px;
            background: url('../../images/landing/block_3.svg') center center / contain no-repeat;
            top: -33px;
            left: -10px;

            @media (min-width: $breakpoint-xs) {
                width: 115px;
                height: 104px;
                top: -38px;
                left: -17px;
            }

            @media (min-width: $breakpoint-sm) {
                top: -38px;
                left: -25px;
            }
        }
    }
}

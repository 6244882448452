.grid-pagination {
  list-style: none;
  padding: 0;
  margin: 0;

  justify-content: center;
  display: flex;

  li {
    font-size: $primary-text-size;

    a {
      display: block;
      cursor: pointer;
      padding: 12px 16px;
      line-height: normal;
      border: 1px solid $grid-border-color;
      color: $primary-bg;
    }

    &.disabled a {
      cursor: default;
    }

    &.selected {
      a {
        border: 1px solid $primary-bg-hover;
        color: $primary-bg-hover
      }
    }

    &:not(.disabled) {
      a {
        &:hover {
          border: 1px solid $primary-bg-hover;
          color: $primary-bg-hover
        }
      }
    }
  }
}

.upload-button {
  display: block;
  width: 100px;
  height: 100px;
  cursor: pointer;
  outline: none;

  &-label {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
  }
}

.empty-hover {
  &:hover {
    background-image: url("/images/upload-pic-hover.svg");
  }

  &.drag-active {
    background-image: url("/images/upload-pic-hover.svg");
  }
}

.empty {
  &-image {
    background: url("/images/upload-pic.svg");
    @extend .empty-hover;
  }

  &-file {
    background: url("/images/upload-document.svg");
    @extend .empty-hover;
  }
}

.uploaded-hover {
  border: 3px dotted transparent;

  &:hover {
    border-color: $secondary-color;
  }

  &.drag-active {
    border-color: $secondary-color;
  }
}

.uploaded {
  &-image {
    justify-content: center;
    align-items: center;
    display: flex;
    background: $body-color;
    width: 94px;
    height: 94px;
    @extend .uploaded-hover;

    img {
      width: 80px;
      height: 80px;
      padding: 10px;
    }
  }

  &-file {
    background: url("/images/upload-pic-uploaded.svg");
    @extend .uploaded-hover;
  }
}

.upload-error {
  margin-top: 5px;
}

.upload-success {
  margin-top: 5px;
  color: $success-color;
}

.upload-file-name {
  font-size: 18px;
  margin-left: 15px;
  line-height: 100px;
}

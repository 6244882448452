.accordions {
  width: 100%;
  margin-bottom: 20px;
}

.accordion {
  margin-bottom: 20px;

  &-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    justify-content: space-between;

    &:hover {
      color: $link-color;
    }

    .showed {
      padding: 0 0 0 10px;
      transform: rotate(180deg);
    }

    .hided {
      padding: 0 10px 0 0;
    }
  }

  &-content {
    height: 0;
    overflow: hidden;
  }

  &-with-actions {
    display: flex;

    .accordion-header {
      width: 100%;
    }
  }

  &.with-actions {
    .button-group {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;

      button {
        margin-right: 20px;

        &.btn {
          padding: 15px 20px;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .icon {
        font-size: 1em;
      }
    }
  }
}

.accordion-flex-content {
  padding: 20px 5px;

  &-item {
    width: 100px;
    display: inline-block;
    margin-bottom: 15px;
    padding: 0 10px;
    vertical-align: top;

    &-header {
      color: $label-color;
      font-weight: 600;
      font-size: $secondary-text-size;
      margin-bottom: 5px;
    }

    &-content {
      font-size: 18px;
      margin-bottom: 2px;
    }

    &-additional-component {
      color: $link-color;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: $body-color;
      }
    }
  }

  &-additional-item {
    color: #75c044;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

.header {
    position: fixed;
    z-index: $zindex-header;
    left: 0;
    right: 0;
    top: 122px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 25px;
    background-color: rgba(0, 19, 16, 0.8);

    @media screen and (min-width: $breakpoint-sm) {
        padding-left: 80px;
        padding-right: 80px;
        left: 80px;
        top: 0;
    }

    .no-global-nav & {
        top: 60px;

        @media screen and (min-width: $breakpoint-sm) {
            top: 0;
        }
    }

    .no-sidebar-menu & {
        top: 0;

        @media screen and (min-width: $breakpoint-sm) {
            left: 0;
            padding-left: 60px;
            padding-right: 80px;
        }

        @media screen and (min-width: $breakpoint-lg) {
            padding-left: 120px;
        }
    }

    .header-name {
        @media screen and (max-width: $breakpoint-sm - 1) {
            display: none;
        }
    }

    .lang-and-logout {
        display: flex;
        align-items: center;

        @media screen and (max-width: $breakpoint-sm - 1) {
            width: 100%;
            justify-content: space-between;
            flex-direction: row-reverse;
        }
    }
}

.question-list-item {
    font-size: 24px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    color: #168574;
    border-radius: 50%;
    transition: color 0.3s;
    padding: 0 5px 3px 3px;

    &:hover,
    &:active {
        color: $body-color;
    }

    &.active {
        color: $body-inverse-color;
        background-color: $body-color;
        border: 2px solid $body-inverse-color;

        &:hover {
            color: $link-color;
        }
    }

    &.disabled {
        background-color: $link-color;
        color: $body-color;

        &:hover {
            color: $body-inverse-color;
        }
    }
}

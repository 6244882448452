@import '../../../styles/colors';
@import '../../../styles/variables';

.cert-program {
    border: 1px solid #a286ff;
    border-radius: 20px;
    padding: 22px 16px 32px;
    position: relative;
    margin-top: 32px;
    font-size: 14px;

    @media (min-width: $breakpoint-sm) {
        padding: 35px 20px 45px;
    }

    @media (min-width: $breakpoint-md) {
        padding: 60px 32px 70px;
        font-size: 16px;
        margin-top: 64px;
    }

    @media (min-width: $breakpoint-lg) {
        padding: 48px 60px 60px 70px;
        font-size: 20px;
    }

    &__number {
        font-weight: 400;
        position: absolute;
        font-size: 64px;
        line-height: 77px;
        top: 7px;
        right: 15px;

        @media (min-width: $breakpoint-sm) {
            top: 26px;
        }

        @media (min-width: $breakpoint-md) {
            top: 58px;
            right: 55px;
        }
    }

    &__top {
        max-width: 810px;

        @media (max-width: $breakpoint-sm) {
            max-width: 460px;
        }
    }

    &__title {
        max-width: 620px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 20px;

        @media (min-width: $breakpoint-sm) {
            margin-bottom: 15px;
        }

        @media (max-width: $breakpoint-sm - 1) {
            padding-right: 35px;
        }

        @media (min-width: $breakpoint-md) {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 35px;
        }

        @media (min-width: $breakpoint-lg) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 15px;
        }
    }

    &__list {
        margin: 30px 0 25px 0;
        list-style: none;
        padding-left: 0;

        @media (min-width: $breakpoint-sm) {
            margin-top: 20px;
            margin-bottom: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 8px;
        }

        @media (min-width: $breakpoint-md) {
            margin-top: 45px;
            margin-bottom: 65px;
            gap: 16px;
        }

        @media (min-width: $breakpoint-lg) {
            margin-top: 60px;
            margin-bottom: 55px;
        }
    }

    &__item {
        margin-top: 16px;
        background-color: rgba(162, 134, 255, 0.27);
        background-repeat: no-repeat;
        background-position: 16px 25px;
        background-size: 40px;
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
        border-radius: 20px;
        padding: 12px;

        @media (min-width: $breakpoint-sm) {
            padding: 16px 16px 40px;
            margin-top: 0;
            background-position: 16px 20px;
            background-size: auto;
        }

        @media (max-width: $breakpoint-sm - 1) {
            display: flex;
            align-items: center;
        }

        @media (min-width: $breakpoint-md) {
            padding: 20px 32px 48px;
            background-position: 32px 25px;
        }
    }

    &__icon {
        min-width: 58px;
        height: 58px;
        display: block;
        margin-bottom: 8px;

        @media (max-width: $breakpoint-sm) {
            height: 40px;
        }
    }

    &__bottom {
        @media (min-width: $breakpoint-sm) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &-text {
            @media (min-width: $breakpoint-sm) {
                max-width: 450px;

                .text-center {
                    margin-top: 0;
                }
            }

            @media (min-width: $breakpoint-md) {
                max-width: 540px;

                .text-center {
                    width: 32%;
                }
            }

            @media (min-width: $breakpoint-lg) {
                max-width: 800px;
            }
        }

        .text-center {
            text-align: center;
            margin-top: 20px;
        }
    }

    &__cert-btn {
        display: inline-block;
        background-color: $main-secondary;
        border: none;
        color: #fff;
        box-shadow: 0 8px 32px rgba(52, 208, 184, 0.48);
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-decoration: none;
        padding: 14px;
        min-width: 232px;
        transition: all 0.3s;

        &:hover {
            background-color: #8349ff;
            box-shadow: 0 8px 32px rgba(162, 134, 255, 0.27);
            outline: none;
        }
    }

    .text-link {
        transition: all 0.3s;
        text-decoration: underline;
        color: $main-secondary-light;

        &:hover {
            color: $main-primary-light;
        }
    }
}

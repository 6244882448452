@include theme(theme-light, #fff, #000, $primary-bg);
@include theme(theme-primary, $primary-bg, #fff, #000);

@import "./test-finish/user-test-result/TestResult.scss";
@import "./test-finish/quit-test/QuitTest.scss";
@import "./passing-test/test-questions-list/TestQuestionsList.scss";
@import "./passing-test/question-view/QuestionView.scss";
@import "./passing-test/show-questions-button/ShowQuestionsButton.scss";
@import "./passing-test/submit-button/SubmitButton.scss";
@import "./UserTest.scss";

.block-test-wrap {
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    display: flex;
    align-items: flex-start;
    padding-top: 50px;
  }
}

.block-test-body {
  display: block;
  flex: 1 1 100%;
  max-width: 1000px;
  animation-duration: 0.5s;
  animation-name: show;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.3;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.block-test-body-inner {
  display: flex;
  justify-content: space-between;
}

.test-body-answers {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1 0 auto;
  height: 440px;
  margin: 6px;
  max-width: 800px;
  transition: opacity 1s ease, right 1s ease;
  scrollbar-color: $primary-text-color transparent;
  scrollbar-width: thin;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary-text-color;
    opacity: 0.5;
    border-radius: 100px;
    background-clip: padding-box;
  }

  @media screen and (max-width: #{$breakpoint-sm - 1}) {
    min-height: 855px;
  }
}

@import "./enter-name/EnterName.scss";
@import "./deleted-account-info/DeletedAccountInfo.scss";

.info-buttons {
  margin-top: 40px;
}

.info-form {
  max-width: 550px;
  margin-bottom: 20px;
}

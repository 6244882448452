.language-picker {
    .dropdown-select {
        min-width: 180px;
    }
}

//hide hook
.hide-language-picker {
    .language-picker {
        visibility: hidden;
    }
}

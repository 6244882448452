.test-body-buttons {
    display: flex;
    margin: 0 0 15px 0;

    @media screen and (max-width: $breakpoint-xs) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .submit-button {
            margin: 0 0 10px 0;
        }
    }

    &__finish {
        padding: 0;
        height: 52px;
        width: 200px;
    }

    .btn {
        padding: 0;
        width: 200px;

        @media screen and (max-width: $breakpoint-xs) {
            margin: 0 0 10px;
            flex: 0 0 45%;
        }
    }
}

.finish-test-modal {
    .paragraph-lead {
        font-size: $main-header-text-size;
        font-weight: 100;
        line-height: 50px;
        max-width: 500px;
        margin-top: 0;
        margin-bottom: 35px;
    }

    .btn {
        @media screen and (max-width: $breakpoint-xs) {
            margin: 0;
            width: 100%;
        }

        + .btn {
            @media screen and (max-width: $breakpoint-xs) {
                margin-top: 10px;
            }
        }
    }
}

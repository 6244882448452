.alert {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 80px;
    margin-right: 80px;

    .icon {
        font-size: $secondary-text-size;
        padding-right: 5px;
    }

    &-info {
        @include background-opacity($info-color, 0.95);
    }

    &-danger {
        @include background-opacity($danger-color, 0.95);
    }

    &-success {
        @include background-opacity($success-color, 0.95);
    }
    &-error {
        @include background-opacity($danger-color, 0.95);
    }
}

.checkbox {
    min-width: 26px;

    &.disabled {
        .checkbox_text {
            cursor: default !important;
        }
    }
}

.checkbox_control {
    @include hidden-input();

    + .checkbox_text {
        position: relative;
        display: block;
        height: 26px;
        line-height: 1.2;
        box-sizing: border-box;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            width: 26px;
            height: 100%;
            background-color: transparent;
            border: 1px solid $input-border-color;
        }
    }
}

.checkbox_text {
    font-size: 14px;
    span {
        margin-left: 40px;
        line-height: 28px;
    }
}

.checkbox_control:checked {
    + .checkbox_text {
        &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 9px;
            top: 5px;
            left: 7px;
            transform: rotate(-45deg);
            border-bottom: solid 2px $link-color;
            border-left: solid 2px $link-color;
        }
    }
}

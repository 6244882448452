.confirm-certificate-data {
  margin: auto;
  width: 600px;

  .paragraph-lead {
    font-weight: 300;
    margin-bottom: 46px;
    margin: 20px 0 35px 0;
  }

  input {
    color: $body-inverse-color;
  }
}

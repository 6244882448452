@include theme(theme-light, #fff, #000, $primary-bg);

.expired-date {
    position: relative;
    top: -20px;
    opacity: 0.5;
}

.certificate {
    margin-left: auto;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: $breakpoint-tablet) {
        margin: 0 auto;
    }

    &-image {
        width: 300px;
        box-shadow: 0 0 15px 0 #00000021;
        display: block;
    }

    &.loading {
        &:before {
            content: '';
            width: 40px;
            height: 40px;
            margin-left: 15px;
            border-radius: 50%;
            border: 7px solid $primary-bg;
            border-top-color: transparent;
            animation: rotate 1.2s linear infinite;
        }

        .certificate-image {
            display: none;
        }
    }

    .hide {
        display: none;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mobile-title {
    width: 100%;
    text-align: center;
}

.certificate-actions {
    margin: auto auto auto 50px;

    @media screen and (max-width: $breakpoint-tablet) {
        margin: 30px auto;
        text-align: center;
    }
}

.social-networks {
    margin-bottom: 25px;

    .social-network-button {
        border: 1px solid $primary-logo-color !important;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 27px;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            border-color: $text-sub-color;
        }
    }
}

.action-link {
    margin-bottom: 20px;

    .link {
        color: $primary-logo-color;

        &:hover:enabled {
            color: $link-color;
        }
    }
}

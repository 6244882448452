.instruction {
    &__radio-in-text {
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        padding-left: 24px;
        padding-right: 0;
        margin-bottom: 0;
    }

    &__checkbox-in-text {
        display: inline-block;
        vertical-align: middle;
    }
}

.category-list {
  margin: 35px 0 30px 0;
  border: none;
  padding: 0;

  &-fields {
    border-top: 1px solid $input-border-color;
    margin-top: 5px;
  }

  &-footer {
    display: flex;
    margin-top: 10px;

    .quantity {
      margin-left: auto;
      text-align: right;
      letter-spacing: 0.01em;
      font-size: 14px;
      margin: 0 45px 0 auto;
      color: $primary-text-color;
    }
  }
}

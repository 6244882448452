$terms-of-use-text-color: #999;

.terms-of-use-item {
    .terms-of-use-title {
        font-size: 28px;
        line-height: 1.8;
        margin-bottom: 15px;
        font-weight: 100;
        text-transform: uppercase;

        @media screen and (max-width: $breakpoint-xs) {
            font-size: 20px;
            line-height: 1.4;
        }

        @media screen and (max-width: 374px) {
            font-size: 18px;
        }
    }

    .terms-of-use-content,
    .terms-of-use-content-list {
        p,
        li {
            font-size: $primary-text-size;
            line-height: 1.6;
            color: $terms-of-use-text-color;
        }

        .link {
            font-size: $primary-text-size;
            line-height: 1.6;
            font-weight: 400;

            @media screen and (max-width: $breakpoint-sm) {
                word-break: break-all;
            }
        }

        .bold {
            font-weight: 700;
        }
    }

    .terms-of-use-subtitle {
        font-size: $primary-text-size;
        line-height: 1.6;
        color: $terms-of-use-text-color;
        margin-bottom: 15px;
    }
}

.date-picker {
  width: 100%;
  max-width: 600px;
  height: 50px;
  margin-top: 5px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    .react-date-picker__wrapper {
      border-bottom: 1px solid $primary-bg;
    }
  }

  &.error {
    .react-date-picker__wrapper {
      border: none;
      border-bottom: 1px solid $danger-color;
      margin-bottom: 5px;
    }
  }

  .react-date-picker__wrapper {
    border: none;
    border-bottom: 1px solid $input-border-color;
  }

  .react-date-picker__inputGroup__input {
    color: $primary-text-color;
    font-size: 20px;
    letter-spacing: 0.01em;
    font-weight: normal;
  }

  .react-date-picker__inputGroup__leadingZero {
    color: $primary-text-color;
    font-size: 20px;
    letter-spacing: 0.01em;
    font-weight: normal;
  }

  .react-date-picker__inputGroup__input:invalid {
    background: transparent;
  }

  .react-calendar__month-view__days__day {
    color: $body-inverse-color;

    &--neighboringMonth {
      color: $label-color;
    }

    &--weekend {
      color: $danger-color;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    color: $body-inverse-color;
  }

  .react-calendar__navigation {
    button {
      color: $body-inverse-color;
    }
  }

  .react-date-picker__calendar-button {
    .icon-calendar-dropdown {
      width: 24px;
      height: 24px;
    }
  }
}

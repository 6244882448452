.link {
    font-weight: 300;
    font-size: $lead-text-size;
    line-height: $lead-line-height;
    color: $link-color;
    transition: color 0.3s ease;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
        cursor: default;
    }

    &:hover:enabled {
        color: $body-color;
    }

    &--landing {
        color: $main-secondary-light;
        text-decoration: underline;

        &:hover {
            color: $main-primary-light;
        }
    }
}

$ownerWidth: 250px;
$emailWidth: 250px;
$companyWidth: 250px;
$countryWidth: 200px;
$testNameWidth: 300px;
$testAreaWidth: 300px;
$productGroupWidth: 300px;
$testCodeWidth: 100px;
$versionWidth: 70px;
$actualWidth: 100px;
$downloadWidth: 80px;
$testLangWidth: 100px;
$creationDateWidth: 120px;
$finishDateWidth: 120px;
$numberWidth: 150px;
$statusWidth: 150px;
$editWidth: 120px;
$paddingItemRight: 20px;

.sf-grid-item {
    display: flex;
    margin: 0;
    justify-content: space-between;
    padding: 20px 25px;
    border-top: 1px solid $grid-border-color;
    width: fit-content;
    &:last-child {
        border-bottom: 1px solid $grid-border-color;
    }

    .link {
        color: $link-color;

        &:hover {
            color: $link-color;
        }
    }

    .cell {
        font-size: $secondary-text-size;
        line-height: $secondary-line-height;
    }
    &-owner {
        min-width: $nameWidth;
        max-width: $nameWidth;
        padding-right: $paddingItemRight;
    }
    &-email {
        width: $emailWidth;
        padding-right: $paddingItemRight;
    }
    &-company {
        width: $companyWidth;
        padding-right: $paddingItemRight;
    }
    &-country {
        width: $countryWidth;
        padding-right: $paddingItemRight;
    }
    &-testName {
        width: $testNameWidth;
        padding-right: $paddingItemRight;
    }
    &-testArea {
        width: $testAreaWidth;
        padding-right: $paddingItemRight;
    }
    &-productGroup {
        width: $productGroupWidth;
        padding-right: $paddingItemRight;
    }
    &-testCode {
        width: $testCodeWidth;
        padding-right: $paddingItemRight;
    }
    &-version {
        width: $versionWidth;
        padding-right: $paddingItemRight;
    }
    &-actual {
        width: $actualWidth;
        padding-right: $paddingItemRight;
    }
    &-download {
        width: $downloadWidth;
        padding-right: $paddingItemRight;
    }
    &-testLang {
        width: $testLangWidth;
        padding-right: $paddingItemRight;
    }
    &-creationDate {
        width: $creationDateWidth;
        padding-right: $paddingItemRight;
    }
    &-finishDate {
        width: $finishDateWidth;
        padding-right: $paddingItemRight;
    }
    &-number {
        width: $numberWidth;
        padding-right: $paddingItemRight;
    }
    &-status {
        width: $statusWidth;
        padding-right: $paddingItemRight;
    }
    &-edit {
        width: $editWidth;
        padding-right: $paddingItemRight;
    }
}

.sf-grid {
    border-collapse: collapse;
    border: none;
    list-style: none;
    padding: 0px;
    margin: 0px 0px 20px 0px;
    &-header {
        display: flex;
        flex-direction: row;
        width: fit-content;
        .cell {
            color: $label-color;
            font-weight: 600;
            font-size: $secondary-text-size;
            line-height: $secondary-line-height;
        }
        &-owner {
            margin-left: 25px;
            padding-bottom: 10px;
            display: flex;
            min-width: $ownerWidth;
            max-width: $ownerWidth;
            padding-right: $paddingItemRight;
        }
        &-email {
            padding-bottom: 10px;
            width: $emailWidth;
            padding-right: $paddingItemRight;
        }
        &-company {
            padding-bottom: 10px;
            width: $companyWidth;
            padding-right: $paddingItemRight;
        }
        &-country {
            padding-bottom: 10px;
            width: $countryWidth;
            padding-right: $paddingItemRight;
        }
        &-testName {
            padding-bottom: 10px;
            width: $testNameWidth;
            padding-right: $paddingItemRight;
        }
        &-testArea {
            padding-bottom: 10px;
            width: $testAreaWidth;
            padding-right: $paddingItemRight;
        }
        &-productGroup {
            padding-bottom: 10px;
            width: $testAreaWidth;
            padding-right: $paddingItemRight;
        }
        &-testCode {
            padding-bottom: 10px;
            width: $testCodeWidth;
            padding-right: $paddingItemRight;
        }
        &-version {
            padding-bottom: 10px;
            width: $versionWidth;
            padding-right: $paddingItemRight;
        }
        &-actual {
            padding-bottom: 10px;
            width: $actualWidth;
            padding-right: $paddingItemRight;
        }
        &-download {
            padding-bottom: 10px;
            width: $downloadWidth;
            padding-right: $paddingItemRight;
        }
        &-testLang {
            padding-bottom: 10px;
            width: $testLangWidth;
            padding-right: $paddingItemRight;
        }
        &-creationDate {
            padding-bottom: 10px;
            width: $creationDateWidth;
            padding-right: $paddingItemRight;
        }
        &-finishDate {
            padding-bottom: 10px;
            width: $finishDateWidth;
            padding-right: $paddingItemRight;
        }
        &-number {
            padding-bottom: 10px;
            width: $numberWidth;
            padding-right: $paddingItemRight;
        }
        &-status {
            padding-bottom: 10px;
            width: $statusWidth;
            padding-right: $paddingItemRight;
        }
        &-edit {
            padding-bottom: 10px;
            width: $editWidth;
            padding-right: $paddingItemRight;
        }
    }
}
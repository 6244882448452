.question-list-statistic {
    .question_list {
        list-style-type: none;
    }
    li {
        padding: 0px 0px 5px 0px;
        margin: 0px 0px 5px 0px;
        li {
            border-bottom: 1px solid white;
        }
    }
    .column-data {
        display: flex;
        flex-direction: row;
        .column-text {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            overflow-wrap: anywhere;
        }
        .is-right {
            font-weight: 800;
        }
        .statistic {
            display: flex;
            width: 100px;
            justify-content: center;
        }
    }
}

@import '../../styles/colors';

.statistic-question-answer {
    display: flex;
    align-items: center;

    & + & {
        margin-top: 12px;
    }

    &_edit {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 2px;
        &.error {
            padding: 1px;
            border: 1px solid $error-color;
        }
        .error_notification {
            color: $error-color;
            margin: 0px;
            padding: 10px 0px 5px 5px;
        }
        .checkbox_new.right_checkbox .checkbox_new_text {
            color: $secondary-color;
        }
        .checkbox_new.error_checkbox .checkbox_new_text {
            color: $error-color;
        }
    }

    &__icon {
        border: 1px solid #cdcdcd;
        background: #f3f3f3;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        margin-right: 12px;
        flex-shrink: 0;

        & .icon {
            width: 100%;
            height: 100%;
            fill: #ffffff !important;
        }
    }

    &__text {
        font-size: 14px;
    }

    &--right & {
        &__icon {
            background: $body-bg;
        }
        &__text {
            color: $secondary-color;
        }
    }

    &--error & {
        &__icon {
            background: $body-bg;
        }
        &__text {
            color: $error-color;
        }
    }
    &--from-users-right & {
        &__icon {
            background: $secondary-color;
        }
    }

    &--from-users-error & {
        &__icon {
            background: $error-color;
        }
    }
}

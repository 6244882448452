.main-nav {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    background-color: $secondary-inverse-color;

    @media screen and (min-width: $breakpoint-sm) {
        position: absolute;
        margin-left: 50px;
        transition: width 0.6s ease;
        width: 0;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
        margin-left: 50px;
    }

    .no-global-nav & {
        display: none;
    }

    .menu-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        @media screen and (min-width: $breakpoint-sm) {
            position: relative;
            left: -50px;
            display: block;
        }

        @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
            left: -50px;
        }

        &-item {
            display: block;
            list-style-type: none;
            width: 25%;

            @media screen and (min-width: $breakpoint-sm) {
                width: auto;
            }

            &:last-child {
                .menu-nav-link .menu-nav-text {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
        }

        &-link {
            font-size: $menu-icon-size;
            line-height: $menu-icon-size * 2;
            display: block;

            transition: none;
            white-space: nowrap;
            cursor: pointer;

            @media screen and (min-width: $breakpoint-sm) {
                height: 50px;
            }

            @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
                height: 50px;

                font-size: 16px;
                line-height: 50px;
            }

            &.active,
            &:hover {
                .menu-nav-icon {
                    background-color: $body-inverse-color;

                    .icon {
                        color: $link-color;
                    }
                }
                .menu-nav-text {
                    color: $link-color;

                    &:before {
                        width: 100%;
                    }
                }
            }
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            transition: background-color 0.3s ease, color 0.3s ease;
            text-align: center;
            background-color: $primary-bg;
            cursor: pointer;
            height: 62px;

            @media screen and (min-width: $breakpoint-sm) {
                position: relative;
                z-index: $zindex-sidebar;
                display: inline-block;
                border-top: 1px solid rgba(0, 0, 0, 0.13);
                border-bottom: 1px solid rgba(0, 0, 0, 0.13);
                width: 50px;
                height: 50px;

                @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
                    height: 50px;
                    width: 50px;
                }
            }

            .icon {
                width: 22px;
                height: 40px;

                @media screen and (min-width: $breakpoint-sm) {
                    width: 20px;
                    height: 50px;
                }

                @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
                    height: 50px;
                    width: 20px;
                }
            }
        }

        &-text {
            display: none;
            position: relative;
            line-height: 50px;
            margin-left: 25px;
            width: 370px;
            height: 50px;
            transition: border-bottom 0.3s ease, color 0.3s ease, transform 0.6s ease;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            transform: translateX(-500px);
            vertical-align: top;
            color: $body-color;
            font-weight: 400;
            cursor: pointer;

            @media screen and (min-width: $breakpoint-sm) {
                display: inline-block;
            }

            @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
                line-height: 50px;
                height: 50px;
            }

            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                content: '';
                transition: width 0.3s;
                border-bottom: 1px solid $link-color;
            }
        }
    }
}

.student-list {
    max-width: 760px;
    max-height: 430px;
    overflow: auto;
    margin-top: 5px;
    scrollbar-color: $input-border-color transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 26px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &-study-center {
        .student-list-row {
            padding-left: 0;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: $input-border-color;
        opacity: 0.5;
        border-radius: 100px;
        border: 10px solid transparent;
        background-clip: padding-box;
    }

    &-row {
        height: 70px;
        border-bottom: 1px solid $input-border-color;
        padding-left: 20px;

        &:first-child {
            border-top: 1px solid $input-border-color;
        }
    }

    &-cell {
        line-height: 70px;
        display: inline-block;
        width: 50%;
        color: $body-color;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

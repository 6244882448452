.form-auto-size-text-area {
  display: block;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid $input-border-color;
  width: 100%;
  color: $body-color;
  font-size: $lead-text-size;
  line-height: $lead-line-height;
  min-height: 36px;
  padding-top: 16px;
  resize: none;

  &.with-errors {
    border-color: $danger-color;
    margin-bottom: 5px;
  }
}

.radio-input-wrapper {
    position: relative;

    .radio-button,
    .switch {
        position: absolute;
        left: 0;
        top: 20px;
    }

    .form-auto-size-text-area,
    .form-text-input {
        padding-left: 70px;
        width: calc(100% - 60px);
    }
}

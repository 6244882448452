.input-group {
  margin: 35px 0 90px 0;

  .inputs {
    display: flex;
  }

  .form-group {
    width: 100%;
    margin-right: 50px;
  }
}

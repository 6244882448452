.router-tabs {
    display: flex;
    margin-bottom: 40px;

    @media screen and (max-width: $breakpoint-md) {
        flex-direction: column;
    }

    &-item {
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        display: inline-block;
        transition: background-color 0.3s, color 0.3s;
        text-align: center;
        white-space: nowrap;
        color: $icon-color;
        border: 2px solid $primary-bg;
        background-color: transparent;
        cursor: pointer;
        padding: 7px 35px;

        &.active {
            background: $primary-bg;
        }
    }
}

.ball {
    display: block;
    background: black;
    margin: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background: radial-gradient(circle at 10px 10px, #00ff15, #000);
    &_red {
        background: radial-gradient(circle at 10px 10px, #ff0000, #000);
    }
}
.ball .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
    -webkit-transform: rotateX(90deg) translateZ(-150px);
    -moz-transform: rotateX(90deg) translateZ(-150px);
    -ms-transform: rotateX(90deg) translateZ(-150px);
    -o-transform: rotateX(90deg) translateZ(-150px);
    transform: rotateX(90deg) translateZ(-150px);
    z-index: -1;
}
.stage {
    width: 30px;
    height: 30px;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -ms-perspective: 1200px;
    -o-perspective: 1200px;
    perspective: 1200px;
    -webkit-perspective-origin: 50% 50%;
    -moz-perspective-origin: 50% 50%;
    -ms-perspective-origin: 50% 50%;
    -o-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;
}

@import './test-question/Question.scss';
@import './question-view-buttons/QuestionViewButtons.scss';

.test-body {
    &-title {
        font-size: 28px;
        line-height: 1.3;
        font-weight: 500;
        max-width: 610px;
        margin: 0 0 10px;

        @media screen and (max-width: $breakpoint-sm) {
            font-size: 24px;
            line-height: 32px;
            max-width: 100%;
        }
    }

    &-remark {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 45px;
        color: $text-sub-color;
    }
}

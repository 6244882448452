.radio-button-wrapper {
  position: relative;

  & .radio-button {
    position: absolute;
    bottom: 5px;
  }

  & .form-text-input {
    padding-left: 60px;
    width: calc(100% - 60px);
  }
}

.question-name {
  padding-left: 40px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  color: $label-color;
  font-weight: 600;
  font-size: $secondary-text-size;
  line-height: $secondary-line-height;
  cursor: pointer;
}

.question-form {
  width: 75%;

  & .form-text-input {
    max-width: none;
  }
}

.questions-list-buttons {
  @media screen and (max-width: $breakpoint-sm) {
    text-align: left;
  }

  button {
    @media screen and (max-width: $breakpoint-sm) {
      width: 200px;
      margin: 0 0 5px 0;
    }
  }
}

.question-accordion {
  .accordion-header {
    padding: 20px 0;
  }

  .accordion-content {
    padding: 0;
  }

  &:first-child {
    border-top: 1px solid $grid-border-color;
  }

  &-item {
    padding: 20px 20px 20px 40px;
    border-top: 1px solid $body-bg;
    border-bottom: 1px solid $body-bg;
    margin-top: -1px;
    background-color: rgba($primary-bg, 0.3);

    &.right {
      color: $link-color;
    }
  }
}

.journal {
    .form-text-input {
        max-width: 100%;
    }
    .btn-primary {
        display: flex;
    }
    &-select-container {
        display: flex;
    }

    &-select {
        width: 100%;
        margin-right: 80px;
    }

    &-selected-data {
        display: flex;

        .icon-column-with-stripes {
            height: 150px;
            width: 22px;
        }
    }

    &-study-group-and-test-data {
        @include background-opacity($primary-bg, 0.15);
        width: 100%;
        display: flex;
        border: 1px solid $primary-bg;
        box-sizing: border-box;
        margin-left: 10px;
        font-weight: 300;
        font-size: 14px;
        min-width: 560px;
        line-height: 26px;
    }

    &-download-link {
        color: $primary-text-color;
        margin: auto 40px auto auto;

        .icon {
            vertical-align: text-top;
            margin-right: 5px;
        }
    }

    &-data {
        margin: 20px 0 20px 20px;

        &-description {
            font-weight: 300;

            span {
                font-weight: 600;
            }
        }
    }
}

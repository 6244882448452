html,
body,
#root {
    height: 100%;
}

body {
    background-color: $body-bg;
    font-family: 'Museo Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: $body-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    overflow-x: hidden;
}

a {
    text-decoration: none;

    &:focus {
        outline: none;
    }
}

button {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    color: $body-color;
    font-size: $lead-text-size;
    cursor: pointer;

    &:hover:enabled,
    &:focus {
        color: $link-color;
        outline: none;
    }
}

.border-box {
    box-sizing: border-box;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.align-items-left {
    align-items: flex-start;
}

.justify-content-space-between {
    justify-content: space-between;
}

.block-center {
    margin: 0 auto;
}

.left-space {
    margin-left: 4px;
}

.right-space {
    margin-right: 4px;
}

.hidden {
    display: none;
}

.break-word {
    word-wrap: break-word;
}

.pre-wrap {
    white-space: pre-wrap;
}

.danger-text {
    color: $danger-color;
}
.confirm-term-of-use {
    a {
        font-size: 14px;
    }
}

.react-dropdown-select-content input::placeholder {
    color: #fff;
    font-size: 16px;
}
.css-ilbirv-InputComponent {
    width: calc(15ch + 5px) !important;
}
.padding-bottom-10 {
    padding-bottom: 10px;
}

$nameWidth: 250px;
$timeWidth: 150px;
$quantityWidth: 100px;
$languageWidth: 150px;
$monthCountWidth: 150px;
$finishDateWidth: 150px;
$testAreaWidth: 250px;
$activeVersionWidth: 100px;
$creationDateWidth: 150px;
$testTypeWidth: 150px;
$paddingItemRight: 20px;

.test-grid-item {
    display: flex;
    margin: 0;
    justify-content: space-between;
    padding: 20px 25px;
    border-top: 1px solid $grid-border-color;
    width: fit-content;

    &:last-child {
        border-bottom: 1px solid $grid-border-color;
    }

    .link {
        color: $body-color;

        &:hover {
            color: $link-color;
        }
    }

    .cell {
        font-size: $secondary-text-size;
        line-height: $secondary-line-height;
    }
    &-name {
        min-width: $nameWidth;
        max-width: $nameWidth;
        padding-right: $paddingItemRight;
    }

    &-time {
        width: $timeWidth;
        padding-right: $paddingItemRight;
    }

    &-quantity {
        width: $quantityWidth;
        padding-right: $paddingItemRight;
    }

    &-language {
        width: $languageWidth;
        padding-right: $paddingItemRight;
    }

    &-month-count {
        width: $monthCountWidth;
        padding-right: $paddingItemRight;
    }

    &-finish-date {
        width: $finishDateWidth;
        padding-right: $paddingItemRight;
    }

    &-test-area {
        width: $testAreaWidth;
        padding-right: $paddingItemRight;
    }

    &-product-group {
        width: $testAreaWidth;
        padding-right: $paddingItemRight;
    }

    &-active-version {
        width: $activeVersionWidth;
        padding-right: $paddingItemRight;
    }

    &-creation-date {
        width: $creationDateWidth;
        padding-right: $paddingItemRight;
    }

    &-test-type {
        width: $testTypeWidth;
        padding-right: $paddingItemRight;
    }

    .link:not(:last-child) {
        padding-right: 30px;
    }
}

.loader-container {
    &--block {
        display: block;
        position: relative;
        height: 200px;
        z-index: 1;

        .loader-background {
            display: none;
        }

        .spinner {
            position: absolute;
        }
    }
}

.loader-background {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: $zindex-loader;
    top: 0;
    background-color: transparentize($body-inverse-color, 0.3);

    .loader-over-sidebar & {
        z-index: $zindex-sidebar + 1;
    }

    @media screen and (max-width: $breakpoint-sm) {
        left: 0;
        top: 122px;
    }
}

.spinner {
    position: fixed;
    z-index: $zindex-loader;
    width: 50px;
    height: 50px;
    border: 8px solid $primary-bg;
    border-bottom-color: transparent;
    border-radius: 50%;
    top: calc(50% - 25px);
    left: 50%;
    animation: 1.2s spinner linear infinite;
    transform: translate(-50%, -50%);
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

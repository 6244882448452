.test-result-value #mask-stoke {
  transition: stroke-dashoffset 2s ease-in-out;
  stroke-dasharray: 1040;
}

.test-result-value {
  [data-test='0'] #mask-stoke {
    stroke-dashoffset: 0;
  }

  [data-test='1'] #mask-stoke {
    stroke-dashoffset: 10.4;
  }

  [data-test='2'] #mask-stoke {
    stroke-dashoffset: 20.8;
  }

  [data-test='3'] #mask-stoke {
    stroke-dashoffset: 31.2;
  }

  [data-test='4'] #mask-stoke {
    stroke-dashoffset: 41.6;
  }

  [data-test='5'] #mask-stoke {
    stroke-dashoffset: 52;
  }

  [data-test='6'] #mask-stoke {
    stroke-dashoffset: 62.4;
  }

  [data-test='7'] #mask-stoke {
    stroke-dashoffset: 72.8;
  }

  [data-test='8'] #mask-stoke {
    stroke-dashoffset: 83.2;
  }

  [data-test='9'] #mask-stoke {
    stroke-dashoffset: 93.6;
  }

  [data-test='10'] #mask-stoke {
    stroke-dashoffset: 104;
  }

  [data-test='11'] #mask-stoke {
    stroke-dashoffset: 114.4;
  }

  [data-test='12'] #mask-stoke {
    stroke-dashoffset: 124.8;
  }

  [data-test='13'] #mask-stoke {
    stroke-dashoffset: 135.2;
  }

  [data-test='14'] #mask-stoke {
    stroke-dashoffset: 145.6;
  }

  [data-test='15'] #mask-stoke {
    stroke-dashoffset: 156;
  }

  [data-test='16'] #mask-stoke {
    stroke-dashoffset: 166.4;
  }

  [data-test='17'] #mask-stoke {
    stroke-dashoffset: 176.8;
  }

  [data-test='18'] #mask-stoke {
    stroke-dashoffset: 187.2;
  }

  [data-test='19'] #mask-stoke {
    stroke-dashoffset: 197.6
  }

  [data-test='20'] #mask-stoke {
    stroke-dashoffset: 208;
  }

  [data-test='21'] #mask-stoke {
    stroke-dashoffset: 218.4;
  }

  [data-test='22'] #mask-stoke {
    stroke-dashoffset: 228.8;
  }

  [data-test='23'] #mask-stoke {
    stroke-dashoffset: 239.2;
  }

  [data-test='24'] #mask-stoke {
    stroke-dashoffset: 249.6;
  }

  [data-test='25'] #mask-stoke {
    stroke-dashoffset: 260;
  }

  [data-test='26'] #mask-stoke {
    stroke-dashoffset: 270.4;
  }

  [data-test='27'] #mask-stoke {
    stroke-dashoffset: 280.8;
  }

  [data-test='28'] #mask-stoke {
    stroke-dashoffset: 291.2;
  }

  [data-test='29'] #mask-stoke {
    stroke-dashoffset: 301.6;
  }

  [data-test='30'] #mask-stoke {
    stroke-dashoffset: 312;
  }

  [data-test='31'] #mask-stoke {
    stroke-dashoffset: 322.4;
  }

  [data-test='32'] #mask-stoke {
    stroke-dashoffset: 332.8;
  }

  [data-test='33'] #mask-stoke {
    stroke-dashoffset: 343.2;
  }

  [data-test='34'] #mask-stoke {
    stroke-dashoffset: 353.6;
  }

  [data-test='35'] #mask-stoke {
    stroke-dashoffset: 364;
  }

  [data-test='36'] #mask-stoke {
    stroke-dashoffset: 374.4;
  }

  [data-test='37'] #mask-stoke {
    stroke-dashoffset: 384.8;
  }

  [data-test='38'] #mask-stoke {
    stroke-dashoffset: 395.2;
  }

  [data-test='39'] #mask-stoke {
    stroke-dashoffset: 405.6;
  }

  [data-test='40'] #mask-stoke {
    stroke-dashoffset: 416;
  }

  [data-test='41'] #mask-stoke {
    stroke-dashoffset: 426.4;
  }

  [data-test='42'] #mask-stoke {
    stroke-dashoffset: 436.8;
  }

  [data-test='43'] #mask-stoke {
    stroke-dashoffset: 447.2;
  }

  [data-test='44'] #mask-stoke {
    stroke-dashoffset: 457.6;
  }

  [data-test='45'] #mask-stoke {
    stroke-dashoffset: 468;
  }

  [data-test='46'] #mask-stoke {
    stroke-dashoffset: 478.4;
  }

  [data-test='47'] #mask-stoke {
    stroke-dashoffset: 488.8;
  }

  [data-test='48'] #mask-stoke {
    stroke-dashoffset: 499.2;
  }

  [data-test='49'] #mask-stoke {
    stroke-dashoffset: 509.6;
  }

  [data-test='50'] #mask-stoke {
    stroke-dashoffset: 520;
  }

  [data-test='51'] #mask-stoke {
    stroke-dashoffset: 530.4;
  }

  [data-test='52'] #mask-stoke {
    stroke-dashoffset: 540.8;
  }

  [data-test='53'] #mask-stoke {
    stroke-dashoffset: 551.2;
  }

  [data-test='54'] #mask-stoke {
    stroke-dashoffset: 561.6;
  }

  [data-test='55'] #mask-stoke {
    stroke-dashoffset: 572;
  }

  [data-test='56'] #mask-stoke {
    stroke-dashoffset: 582.4;
  }

  [data-test='57'] #mask-stoke {
    stroke-dashoffset: 592.8;
  }

  [data-test='58'] #mask-stoke {
    stroke-dashoffset: 603.2;
  }

  [data-test='59'] #mask-stoke {
    stroke-dashoffset: 613.6;
  }

  [data-test='60'] #mask-stoke {
    stroke-dashoffset: 624;
  }

  [data-test='61'] #mask-stoke {
    stroke-dashoffset: 634.4;
  }

  [data-test='62'] #mask-stoke {
    stroke-dashoffset: 644.8;
  }

  [data-test='63'] #mask-stoke {
    stroke-dashoffset: 655.2;
  }

  [data-test='64'] #mask-stoke {
    stroke-dashoffset: 665.6;
  }

  [data-test='65'] #mask-stoke {
    stroke-dashoffset: 676;
  }

  [data-test='66'] #mask-stoke {
    stroke-dashoffset: 686.4;
  }

  [data-test='67'] #mask-stoke {
    stroke-dashoffset: 696.8;
  }

  [data-test='68'] #mask-stoke {
    stroke-dashoffset: 707.2;
  }

  [data-test='69'] #mask-stoke {
    stroke-dashoffset: 717.6;
  }

  [data-test='70'] #mask-stoke {
    stroke-dashoffset: 728;
  }

  [data-test='71'] #mask-stoke {
    stroke-dashoffset: 738.4;
  }

  [data-test='72'] #mask-stoke {
    stroke-dashoffset: 748.8;
  }

  [data-test='73'] #mask-stoke {
    stroke-dashoffset: 759.2;
  }

  [data-test='74'] #mask-stoke {
    stroke-dashoffset: 769.6;
  }

  [data-test='75'] #mask-stoke {
    stroke-dashoffset: 780;
  }

  [data-test='76'] #mask-stoke {
    stroke-dashoffset: 790.4;
  }

  [data-test='77'] #mask-stoke {
    stroke-dashoffset: 800.8;
  }

  [data-test='78'] #mask-stoke {
    stroke-dashoffset: 811.2;
  }

  [data-test='79'] #mask-stoke {
    stroke-dashoffset: 821.6;
  }

  [data-test='80'] #mask-stoke {
    stroke-dashoffset: 832;
  }

  [data-test='81'] #mask-stoke {
    stroke-dashoffset: 842.4;
  }

  [data-test='82'] #mask-stoke {
    stroke-dashoffset: 852.8;
  }

  [data-test='83'] #mask-stoke {
    stroke-dashoffset: 863.2;
  }

  [data-test='84'] #mask-stoke {
    stroke-dashoffset: 873.6;
  }

  [data-test='85'] #mask-stoke {
    stroke-dashoffset: 884;
  }

  [data-test='86'] #mask-stoke {
    stroke-dashoffset: 894.4;
  }

  [data-test='87'] #mask-stoke {
    stroke-dashoffset: 904.8;
  }

  [data-test='88'] #mask-stoke {
    stroke-dashoffset: 915.2;
  }

  [data-test='89'] #mask-stoke {
    stroke-dashoffset: 925.6;
  }

  [data-test='90'] #mask-stoke {
    stroke-dashoffset: 936;
  }

  [data-test='91'] #mask-stoke {
    stroke-dashoffset: 946.4;
  }

  [data-test='92'] #mask-stoke {
    stroke-dashoffset: 956.8;
  }

  [data-test='93'] #mask-stoke {
    stroke-dashoffset: 967.2;
  }

  [data-test='94'] #mask-stoke {
    stroke-dashoffset: 977.6;
  }

  [data-test='95'] #mask-stoke {
    stroke-dashoffset: 988;
  }

  [data-test='96'] #mask-stoke {
    stroke-dashoffset: 998.4;
  }

  [data-test='97'] #mask-stoke {
    stroke-dashoffset: 1008.8;
  }

  [data-test='98'] #mask-stoke {
    stroke-dashoffset: 1019.2;
  }

  [data-test='99'] #mask-stoke {
    stroke-dashoffset: 1029.6;
  }

  [data-test='100'] #mask-stoke {
    stroke-dashoffset: 1040;
  }
}

.toggle {
  position: relative;
  display: inline-block;
  width: 336px;
  height: 52px;
  cursor: pointer;
  border: 2px solid $primary-bg;

  input {
    display: none;
  }

  #second-option {
    &:checked + span {
      &:before {
        transform: translateX(143px);
      }

      .icon-check-mark {
        transform: translate(143px, 50%);
      }

      .second-option {
        transform: translate(-50px, 50%);
      }

      .first-option {
        width: 143px;
        left: 0;
      }
    }
  }
}

.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  &:before {
    background-color: $primary-bg;
    position: absolute;
    content: "";
    height: 51px;
    width: 192px;
    transition: 0.3s;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.16),
      0px 3px 8px rgba(0, 0, 0, 0.15);
  }
}

.first-option {
  text-align: center;
  cursor: pointer;
  line-height: 51px;
  height: 51px;
  position: absolute;
  transform: translate(0, 50%);
  bottom: 50%;
  left: 40px;
}

.second-option {
  cursor: pointer;
  width: 143px;
  line-height: 51px;
  height: 51px;
  transition: 0.3s;
  position: absolute;
  text-align: center;
  right: 0;
  transform: translate(0, 50%);
  bottom: 50%;
}

.icon-check-mark {
  transition: 0.3s;
  position: absolute;
  left: 128px;
  transform: translate(0, 50%);
  bottom: 50%;
}

.user-test-timer {
  margin: 12px 0 45px;
  color: $body-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;

  @media screen and (min-width: $breakpoint-sm) {
    display: block;
    text-align: left;
    margin-bottom: 45px;
  }

  @media screen and (min-width: $breakpoint-md) {
    margin-bottom: 32px;
  }

  .theme-light & {
    color: $body-inverse-color;
  }

  .timer-value {
    display: inline-block;
    vertical-align: middle;
    width: 218px;
    font-size: $main-header-text-size;
    font-weight: 700;
    line-height: $main-header-text-size;
    margin-right: 14px;

    @media screen and (min-width: $breakpoint-sm) {
      font-size: 52px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 10px;
    }
  }

  .timer-message {
    display: inline-block;
    vertical-align: middle;
    padding: 0 40px;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;

    @media screen and (min-width: $breakpoint-sm) {
      width: 170px;
      padding: 0;
    }
  }
}

.desktop-user-test-timer {
  @media screen and (min-width: $breakpoint-lg) {
    position: absolute;
    top: 20px;
    left: 400px;
    z-index: $zindex-header;
  }
}

$primary-text-size: 16px;
$secondary-text-size: 14px;
$lead-text-size: 20px;
$lead-text-size-sm: 14px;
$header-text-size: 20px;
$main-header-text-size: 42px;
$menu-icon-size: 24px;
$input-text-size: 16px;
$title-text-size: 12px;
$lead-title-text-size: 18px;

$footer-landing-text-size: 15px;

$primary-line-height: 20px;
$secondary-line-height: 16px;
$lead-line-height: 24px;
$header-line-height: 24px;
$main-header-line-height: 42px;
$footer-line-height: 36px;

$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-tablet: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-extra-lg: 1679px;

$zindex-header: 50;
$zindex-modal: 60;
$zindex-loader: 700;
$zindex-sidebar: 800;
$zindex-fixed: 900;
$zindex-dropdown: 1000;
$zindex-below-content: -1;

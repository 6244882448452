.form-text-input {
    display: block;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $input-border-color;
    width: 100%;
    max-width: 600px;
    height: 50px;
    color: $body-color;
    font-size: $lead-text-size;
    line-height: $lead-line-height;

    &:disabled {
        opacity: 0.3;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        font-size: $lead-text-size;
        line-height: $lead-line-height;
        -webkit-text-fill-color: $body-color;
        box-shadow: 0 0 0 1000px $body-bg inset;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }

    &.with-errors {
        border-color: $danger-color;
        margin-bottom: 5px;
    }
}

@import './LanguagePicker/LanguagePicker.scss';
@import './MainNav/MainNav.scss';

.sidebar-menu {
    background-color: $primary-bg;
    z-index: $zindex-sidebar;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;

    @media screen and (min-width: $breakpoint-sm) {
        right: auto;
        bottom: 0;
        width: 50px;
        height: 100%;

        @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
            width: 50px;
        }
    }

    .no-sidebar-menu & {
        display: none;
    }

    .no-global-nav & {
        background-color: transparent;
    }

    .no-sidebar-menu & {
        display: none;
    }

    &:hover {
        .main-nav {
            @media screen and (min-width: $breakpoint-sm) {
                width: 540px;
            }
        }

        .menu-nav-text {
            @media screen and (min-width: $breakpoint-sm) {
                transform: translateX(0);
            }
        }
    }

    @media screen and (max-width: $breakpoint-sm - 1) {
        .react-dropdown-select {
            margin-left: 12px;
        }
    }

    &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        padding: 15px 0 0;

        @media screen and (max-width: $breakpoint-sm - 1) {
            padding-left: 25px;
            padding-right: 25px;
        }

        @media screen and (min-width: $breakpoint-sm) {
            display: none;
        }

        .header-name {
            @media screen and (min-width: $breakpoint-sm) {
                display: none;
            }
        }
    }
}

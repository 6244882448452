@import '../../styles/colors';

.test-answers-statistic {
    min-width: 500px;

    &__common-info {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-top: 1px solid #ededed;
        padding: 20px 0;
        margin-top: 8px;
    }

    &__common-info-name {
        font-size: 12px;
        color: inherit;
        text-align: center;
    }

    &__common-info-value {
        font-size: 20px;
        text-align: center;
        margin-top: 8px;
    }

    &__icon {
        width: 60px;
        height: 60px;

        border: 3px solid;
        border-radius: 100px;

        .icon {
            width: 100%;
            height: 100%;
        }
    }

    &__question-title {
        margin: 20px 0 12px 0;
        font-size: 16px;
        font-weight: bold;
    }

    &--fail & {
        &__common-info-value {
            color: $error-color;
        }

        &__icon {
            border-color: $error-color;

            .icon {
                fill: $error-color !important;
            }
        }
    }

    &--success & {
        &__common-info-value {
            color: $secondary-color;
        }

        &__icon {
            border-color: $secondary-color;

            .icon {
                fill: $secondary-color !important;
            }
        }
    }
    a {
        padding-top: 30px;
        display: block;
    }
}

@import "./StudentList/StudentList.scss";

.study-group-form-buttons {
  margin-top: 90px;
}

.study-group-select-test {
  .react-dropdown-select-dropdown {
    top: 55px;
  }
}

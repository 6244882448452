.container {
    display: flex;
    margin-left: 0;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;

    &--items-center {
        align-items: center;
    }

    &--items-end {
        align-items: end;
    }
}

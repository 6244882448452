.user-card {
  .go-back {
    display: inline-block;
    margin-bottom: 52px;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
      color: $link-color;

      .icon path {
        transition: all 0.3s linear;
        color: $link-color;
        stroke: $link-color;
      }
    }

    &:not(:hover) {
      .icon path {
        transition: all 0.3s linear;
        color: $primary-text-color;
        stroke: $primary-text-color;
      }
    }

    .icon {
      vertical-align: text-bottom;
      width: 19px;
      height: 19px;
    }

    span {
      margin-left: 10px;
      font-weight: 300;
      font-size: 20px;
    }
  }

  .title {
    margin-bottom: 15px;
  }

  &-mail {
    display: flex;
    align-items: center;

    .icon {
      height: 22px;
      width: 22px;
      &:hover {
        color: transparent;
      }
    }

    span {
      font-size: 20px;
      margin-left: 10px;
    }
  }
}

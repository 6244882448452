$body-bg: #001310;
$body-color: #ffffff;
$body-inverse-color: #000000;
$secondary-inverse-color: #252525;
$text-sub-color: #8d8d8d;

$primary-bg: #006d5c;
$primary-bg-hover: #0fa089;
$primary-border-color: #005f50;
$primary-text-color: #ffffff;
$primary-text-color-add: #f1f1f1;
$primary-border-color: #005f50;
$primary-icon-color: #006d5c;
$primary-logo-color: #03a88e;
$secondary-color: #006d5c;
$error-color: #ee5145;

$footer-text-color: $text-sub-color;

$link-color: #75c044;
$icon-color: #ffffff;
$icon-hover-color: #75c044;
$label-color: #666666;
$input-border-color: #474747;
$grid-border-color: #22322f;

$dropdown-active-bg: #1f2c2a;
$dropdown-hover-bg: $primary-bg;

$danger-color: #ff3b30;
$info-color: #007bee;
$success-color: #75c044;

$main-primary: #6e28dc;
$main-primary-light: #a286ff;
$main-secondary: #00a88e;
$main-secondary-light: #34d1b6;

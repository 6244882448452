@import "./AttemptsGridRow/AttemptsGridRow.scss";

.attempt-grid {
  border: none;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  scrollbar-color: $input-border-color transparent;
  scrollbar-width: thin;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 24px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $input-border-color;
    opacity: 0.5;
    border-radius: 100px;
    border: 10px solid transparent;
    background-clip: padding-box;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    color: $label-color;
    font-weight: 600;
    font-size: $secondary-text-size;
    padding-bottom: 10px;
    padding-right: 20px;

    .cell {
      min-width: 150px;
    }

    &-name {
      min-width: 380px;
      max-width: 380px;
      padding-right: 50px;
      margin: 0;
    }

    &-result {
      min-width: 250px;
      max-width: 250px;
    }
  }
}

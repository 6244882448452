@import './TextInput/TextInput.scss';
@import './ValidationMessage/ValidationMessage.scss';
@import './Switch/Switch.scss';
@import './Toggle/Toggle.scss';
@import './AutoSizeTextarea/AutoSizeTextarea.scss';

.form-label {
    color: $label-color;
    font-weight: 600;
}

.form-group {
    border: none;
    padding: 0;
    margin-bottom: 25px;
    width: 100%;
}

.form-field {
    width: 100%;
    max-width: 600px;
    height: 50px;
}

@import '../../styles/colors';

.user-card,
.certificates {
    margin: 5px;
    padding: 15px 5px 5px 5px;
    border: 1px solid $body-color;
    margin-top: 20px;
}

.certificate-card {
    border-bottom: 1px solid $body-color;
    padding-top: 10px;
}

.certificate-card:last-child {
    border-bottom: 1px solid $body-bg;
}

.landing-container {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;

    @media (min-width: 1024px) {
        max-width: 998px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 1200px) {
        max-width: 1250px;
    }
}

.block-test-button {
  border: 2px solid $primary-bg;
  color: $body-inverse-color;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 700;
  position: relative;
  padding: 14px 25px 13px;
  border-radius: 26px;
  flex: 0 0 240px;
  max-width: 240px;
  box-sizing: border-box;
  margin-right: 0;
  margin-bottom: 92px;
  cursor: pointer;

  @media screen and (min-width: $breakpoint-lg) {
    margin-right: 45px;
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 55px;
  }

  @media screen and (max-width: 375px) {
    max-width: 200px;
  }

  .theme-primary & {
    border: $body-color;
    background-color: $body-color;
  }

  &.active {
    background-color: $body-color;

    &::after {
      opacity: 0;
    }
  }

  &::after {
    content: "";
    width: 0;
    border-left: 7px solid #000;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    transition: color 0.3s ease;

    @media screen and (max-width: 375px) {
      right: 13px;
    }
  }

  &:hover {
    &::after {
      border-left: 8px solid $icon-hover-color;
      border-top-width: 6px;
      border-bottom-width: 6px;
    }
  }
}

.test-body-close {
  cursor: pointer;
  background-color: $body-color;
  transition: 0.3s ease;
  text-align: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (min-width: $breakpoint-lg) {
    position: relative;
  }

  &:hover,
  &:active {
    background-color: $primary-bg;

    &::before,
    &::after {
      background-color: $body-color;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2px;
    height: 20px;
    background-color: $body-inverse-color;
    transition: 0.3s ease;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

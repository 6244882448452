.change_name-modal {
    max-width: 700px;
    max-height: 80%;
    text-align: left;
    scrollbar-color: $text-sub-color transparent;
    scrollbar-width: thin;
    overflow-y: auto;

    &::-webkit-scrollbar {
        height: 24px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background: $text-sub-color;
        opacity: 0.5;
        border-radius: 100px;
        border: 6px solid transparent;
        background-clip: padding-box;
    }

    &-title {
        color: $body-color;
        font-size: 30px;
        font-weight: 100;
        margin: 25px 0;
    }

    &-close {
        position: absolute;
        top: 0px;
        right: 0px;

        .icon {
            width: 20px;
            height: 20px;
            padding: 10px;
            cursor: pointer;
            color: $icon-color;
            &:hover {
                color: $icon-hover-color;
            }
        }
    }
}

.deleted-account-info-page {
  padding: 0 25px 120px 25px;

  @media screen and (max-width: $breakpoint-sm) {
    flex: 0;
  }
}

.deleted-account-info {
  justify-content: center;

  &-header {
    padding-left: 40px;

    @media screen and (max-width: $breakpoint-sm) {
      padding-left: 25px;
    }
  }

  &-content {
    max-width: 500px;
  }

  &-text {
    font-size: $lead-text-size;
    font-weight: 300;
    line-height: $lead-line-height;
    margin-bottom: 45px;
    word-wrap: break-word;
    max-width: 420px;

    @media screen and (max-width: $breakpoint-tablet-landscape) {
      max-width: 100%;
    }
  }
}

.quit-test {
  margin: auto;
  max-width: 650px;

  @media screen and (min-width: $breakpoint-lg) {
    padding-right: 60px;
  }

  .title {
    margin-bottom: 45px;

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 25px;
    }
  }

  .paragraph-quit-message {
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 45px;
  }

  .btn {
    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
}

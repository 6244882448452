@mixin theme($name, $color, $secondary-color, $additional-color) {
    .#{$name} {
        background-color: $color;
        color: $secondary-color;

        .header {
            @include background-opacity($color, 0.8);

            a {
                color: $secondary-color;
            }

            .logout::before {
                color: $secondary-color;
            }
        }

        .icon {
            color: $secondary-color;
        }

        .footer-logo {
            .icon {
                color: $secondary-color;

                &:hover {
                    color: $secondary-color;
                }
            }
        }

        .sidebar-menu {
            background: $color;

            .main-nav {
                display: none;
            }
        }
    }
}

.page-inner {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 76px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: $breakpoint-extra-lg) {
    max-width: 1353px;
    padding: 0 50px;
  }

  @media screen and (max-width: $breakpoint-lg - 1) {
    max-width: 1024px;
    padding: 0 40px;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

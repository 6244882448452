@import '../../styles/colors';
@import '../../styles/variables';

.react-dropdown-select {
    &.dropdown-select {
        border: none;
        border-bottom: 1px solid $input-border-color;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        font-size: $primary-text-size;
        &.multy-select {
            height: auto !important;
            .react-dropdown-select-option {
                background: $primary-icon-color;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        &:hover,
        &:focus,
        &:active,
        &:focus-within {
            border-color: $primary-bg;
            box-shadow: none;
        }

        &.with-error {
            border-bottom: 1px solid $danger-color;
            margin-bottom: 5px;
        }
    }

    .react-dropdown-select-input {
        color: $primary-text-color-add !important;
    }
}

.react-dropdown-select-dropdown {
    z-index: $zindex-dropdown !important;
    background-color: $body-bg !important;
    border: 1px solid $input-border-color !important;
    border-radius: 0 !important;

    .react-dropdown-select-item {
        border: none !important;
        font-size: $primary-text-size !important;
        padding: 10px 15px !important;

        &-selected {
            background-color: $dropdown-active-bg !important;
        }

        &:hover,
        &:focus {
            background-color: $dropdown-hover-bg !important;
        }
    }
}

.theme-light {
    .react-dropdown-select-dropdown {
        background-color: #ffffff !important;
        border: 1px solid #ffffff !important;
        color: #000000;

        .react-dropdown-select-item {
            &:hover,
            &:focus {
                background-color: $dropdown-hover-bg !important;
                color: #ffffff;
            }
        }
    }
}

.theme-landing {
    .react-dropdown-select {
        border-bottom-color: #fff;

        &.dropdown-select {
            &:hover,
            &:focus,
            &:active,
            &:focus-within {
                transition: all 0.3s;
                border-color: $main-primary-light;
            }
        }
    }

    .react-dropdown-select-dropdown {
        .react-dropdown-select-item {
            &-selected {
                background-color: opacify($main-primary, 0.01) !important;
            }

            &:hover,
            &:focus {
                background-color: $main-primary !important;
                color: #ffffff;
            }
        }
    }
}

.all-certificates {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;

    .certificate-container {
        position: relative;
        box-sizing: border-box;
        width: 360px;
        min-height: 205px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $main-secondary-light;
        border-radius: 20px 0 20px 0;
        flex-wrap: nowrap;
        transition: 0.5s;
        padding: 0;
        margin: 0 40px 60px 0;
        overflow: hidden;
        cursor: pointer;
        .certificate-upper {
            display: flex;
            flex-direction: row;
            div {
                padding: 16px;
                flex: 1;
            }
            img {
                width: 130px;
                margin-right: -1px;
                margin-top: -1px;
                height: 98px;
            }
            img.small {
                width: 100px;
            }
        }
        .certificate-footer {
            display: flex;
            flex-direction: row;
            .column {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 0 16px 16px 16px;
            }
        }

        .paragraph {
            margin-bottom: 5px;
        }

        .preshow-certificate {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: red;
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparentize($body-bg, 0.1);
        }

        @media screen and (max-width: $breakpoint-xs) {
            min-width: 270px;
        }

        &:hover .preshow-certificate {
            transition: 0.5s;
            opacity: 1;
        }
    }
}
.search-field {
    flex-wrap: nowrap;
    fieldset,
    button {
        margin-right: 5px;
    }
}

.category-grid-item {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  border-top: 1px solid $grid-border-color;

  &:last-child {
    border-bottom: 1px solid $grid-border-color;
  }

  .link {
    color: $body-color;

    &:hover {
      color: $link-color;
    }
  }

  &-quantity {
    font-size: $secondary-text-size;
    line-height: $secondary-line-height;
    padding-right: 70px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $body-color;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    margin-right: 20px;

    img {
      max-width: 35px;
      max-height: 35px;
    }

    &-image {
      display: block;
      font-size: $primary-text-size;
      color: $primary-icon-color;
      width: 100%;
      height: 100%;
      max-width: 20px;
      max-height: 20px;
    }
  }

  .link:not(:last-child) {
    padding-right: 30px;
  }
}

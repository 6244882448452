.switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 52px;
    height: 32px;

    &__container {
        display: inline-block;
        cursor: pointer;
    }

    input {
        display: none;
        &:checked + span {
            background-color: #75c044;
            &:before {
                transform: translateX(20px);
            }
        }
    }

    &__small {
        width: 38px;
        height: 18px;
    }

    &__small & {
        &-slider {
            &:before {
                height: 12px;
                width: 12px;
            }
        }
    }

    &-slider {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 100%;
        background-color: $label-color;
        border-radius: 36.5px;
        transition: 0.3s;
        &:before {
            margin: 2px;
            position: absolute;
            content: '';
            height: 27px;
            width: 27px;
            background-color: $primary-text-color;
            transition: 0.3s;
            border-radius: 100px;
            border: 0.5px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.16), 0 3px 8px rgba(0, 0, 0, 0.15);
        }
    }

    &-label {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
    }
}

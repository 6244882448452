@import '../../styles/variables';
@import '../../styles/colors';

.header-landing {
    background: rgba(162, 134, 255, 0.75);
    position: relative;
    z-index: 1;

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: rgba(162, 134, 255, 0.22);
        -webkit-backdrop-filter: blur(60px);
        backdrop-filter: blur(60px);
    }

    &__wrap {
        display: flex;
        height: 100px;
        align-items: center;
        position: relative;

        @media (max-width: $breakpoint-xs) {
            flex-direction: column;
            padding: 20px;
            height: auto;
        }
    }

    &__logo {
        @media (min-width: 768px) {
            margin-left: 10px;
        }

        @media (min-width: 1200px) {
            margin-left: 70px;
        }
    }

    @media (hover: hover) and (pointer: fine) {
        .header__login:hover path {
            stroke: var(--violet);
        }
    }

    &__login-and-lang {
        margin-left: auto;
        display: flex;

        @media (max-width: $breakpoint-xs) {
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__login {
        margin-right: 10px;

        @media (min-width: $breakpoint-md) {
            margin-right: 54px;
        }

        & * {
            transition: all 0.3s;
        }

        .icon {
            fill: #fff;

            &:hover {
                fill: $main-primary-light;
            }
        }
    }

    .person-link-logo {
        background: $main-primary;
        color: #fff;
    }
}

.top-space-3x {
    margin-top: 12px !important;
}

.top-space-10x {
    margin-top: 40px;
}

.left-space {
    margin-left: 4px;
}

.right-space {
    margin-right: 4px;
}

.right-space-3x {
    margin-right: 12px;
}

.p-left-space-3x {
    padding-left: 12px;
}

.p-right-space-3x {
    padding-right: 12px;
}

.bottom-space-3x {
    margin-bottom: 12px;
}

.bottom-space-6x {
    margin-bottom: 50px;
}
.scroll {
    overflow: auto;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 270px 25px 20px 25px;
  flex: 1 0 auto;

  @media screen and (min-width: $breakpoint-sm) {
    padding-right: 80px;
    padding-left: 160px;
    padding-top: 110px;
  }

  &.page-content-center {
    justify-content: center;
  }

  .no-global-nav & {
    padding-top: 210px;

    @media screen and (min-width: $breakpoint-sm) {
      padding-top: 110px;
    }
  }

  .no-sidebar-menu & {
    padding-top: 150px;

    @media screen and (min-width: $breakpoint-sm) {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 110px;
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-left: 120px;
    }
  }
}

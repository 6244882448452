.page-not-found {
  background-image: url("/images/404/page-not-found.svg");
  overflow-x: hidden;

  .header {
    left: 0;
    background-color: transparent;
    top: 0;

    .person {
      display: none;
    }

    .header-name {
      display: block;
    }
  }

  .footer {
    margin-left: 0;
  }

  .page-wrapper {
    min-height: 350px;
  }

  #root,
  .page-wrapper {
    height: 100%;
    padding: 0 15px;
  }

  .sidebar-menu {
    display: none;
  }

  &-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 20%;
      left: 100%;
      background-image: url("/images/404/diagonal.svg");
      pointer-events: none;
      touch-action: none;
      width: 154px;
      height: 49px;

      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 80%;
      right: 100%;
      background-image: url("/images/404/wave.svg");
      pointer-events: none;
      touch-action: none;
      width: 180px;
      height: 34px;

      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }
  }

  &-header {
    position: relative;
    z-index: $zindex-header;
    font-size: $main-header-text-size * 1.5;
    font-weight: 900;
    line-height: $main-header-line-height * 1.2;
    text-align: center;
    color: $link-color;
    margin: 10px auto;

    @media screen and (max-width: $breakpoint-sm) {
      font-size: $main-header-text-size;
      line-height: $main-header-line-height;
    }

    span {
      display: block;
      font-size: $lead-text-size;
      font-weight: 300;
      color: $body-color;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: $lead-text-size-sm;
      }
    }
  }

  &-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

    button {
      position: relative;
      margin: 0 auto;
    }
  }
}

.answer {
    &__container {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__switch {
        width: 80px;
        margin-top: -24px;
    }
    &__delete {
        position: absolute;
        right: 0;
        top: 40%;
        transform: translate(0, -50%);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }
}

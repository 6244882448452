.question-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        .question-item-value {
            color: $body-color;
            background-color: $primary-bg;
        }
    }
}

.question-item-input {
    @include hidden-input();

    &:checked + .question-item-value {
        color: $primary-text-color-add;
        background-color: $primary-bg;
    }
}

.question-item-value {
    font-size: 36px;
    font-weight: 700;
    line-height: 55px;
    flex: 0 0 55px;
    height: 55px;
    text-align: center;
    color: $primary-bg;
    border-radius: 50%;
    background-color: $primary-text-color-add;
    transition: color 0.3s ease, background-color 0.3s ease;
    margin-right: 13px;
}

.question-item-text {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 300;
    color: $body-inverse-color;
    word-break: break-word;
}

.test-body-question {
    margin: 0 -22px 16px;

    @media screen and (min-width: $breakpoint-sm) {
        display: flex;
        flex-wrap: wrap;
    }
}

.question-item-wrap {
    flex: 0 0 50%;
    padding: 0 22px 25px;
    box-sizing: border-box;
    max-width: 500px;

    @media screen and (min-width: $breakpoint-sm) {
        padding: 0 22px 36px;
    }
}

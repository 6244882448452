.btn {
    font-size: $lead-text-size;
    font-weight: 300;
    line-height: $lead-line-height;
    display: inline-block;
    -webkit-transition: background-color 0.3s, color 0.3s;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    white-space: nowrap;
    color: $body-color;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-sm) {
        padding: 10px 30px;
        font-size: $lead-text-size-sm;
    }

    &-pSize {
        &-small {
            padding: 4px;
        }

        &-none {
            padding: 0;
        }

        &-big {
            padding: 14px 70px;

            @media screen and (max-width: $breakpoint-sm) {
                padding: 10px 30px;
            }
        }
    }

    &:disabled {
        opacity: 0.7;
        cursor: default;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }

    &:hover:enabled,
    &:focus:enabled,
    &:active:enabled {
        outline: none;
        box-shadow: none;
        color: $body-color;
    }

    &-primary {
        background-color: $primary-bg;
        color: $primary-text-color;

        &:hover:enabled {
            background-color: $primary-bg-hover;
        }
    }

    &-secondary {
        border: 2px solid $primary-bg;
        color: $primary-text-color;

        @media screen and (max-width: $breakpoint-sm) {
            padding: 8px 30px;
            font-size: $lead-text-size-sm;
        }

        &:hover:enabled {
            background-color: $primary-bg;
        }
    }

    &-link {
        color: $secondary-color;

        &:hover:enabled {
            background-color: $primary-bg-hover;
            color: $primary-text-color;
        }

        &:focus:enabled,
        &:active:enabled {
            color: $secondary-color;
        }
    }

    &-additional {
        background-color: $text-sub-color;

        &:hover:enabled {
            color: $link-color;
        }

        &:focus:enabled,
        &:active:enabled {
            color: $link-color;
        }
    }
    &-loader {
        display: inline-block;
        border: 4px solid #fff;
        border-bottom-color: transparent;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        animation: 1.2s spinner linear infinite;
        margin-right: 8px;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.theme-light {
    .btn-secondary {
        color: $body-inverse-color;

        &:hover:enabled {
            color: $primary-text-color;
        }
    }
}

.paragraph {
    color: $body-color;
    margin: 0 0 25px 0;
    font-weight: 400;
    font-size: $primary-text-size;
    line-height: $primary-line-height;

    &-top-space-3x {
        padding-top: 12px;
    }

    &-lead {
        font-size: $lead-text-size;
        line-height: $lead-line-height;
        &-title {
            @extend .paragraph-lead;
            font-size: $lead-title-text-size;
            font-weight: 600;
        }
        &-common {
            @extend .paragraph-lead;
            font-size: $secondary-text-size;
            margin-bottom: 0px !important;
        }
    }

    &-success {
        color: $link-color;
    }

    &-secondary {
        color: $label-color;
        font-weight: 600;
        &-title {
            @extend .paragraph-secondary;
            font-weight: 300;
            font-size: $title-text-size;
            margin-bottom: 0px !important;
        }
    }

    &-danger {
        color: $danger-color;
    }

    &-excerpt {
        font-size: $lead-text-size;
        font-weight: 300;
        line-height: $lead-line-height;

        strong {
            font-weight: 700;
        }

        &-with-mark {
            font-size: $lead-text-size;
            line-height: $lead-line-height;
            font-weight: 300;

            &::before {
                position: absolute;
                content: '';
                background-repeat: no-repeat;
                background-position: center;
                display: block;
                width: 16px;
                height: 8px;
                border-bottom: solid 2px $icon-hover-color;
                border-left: solid 2px $icon-hover-color;
                transform: rotate(-45deg);
                margin-left: -30px;
            }
        }
    }

    .theme-light & {
        color: $body-inverse-color;
    }
}

@import '../../styles/colors';

.study-center-test-statistics-grid__status-icon {
    .icon {
        &-check-mark-2 {
            width: 30px;
            height: 30px;
            color: $success-color;
        }

        &-close {
            width: 30px;
            height: 30px;
            color: $error-color;
        }
    }
}

$privacy-text-color: #999;

.privacy-item {
    .privacy-table {
        border-collapse: collapse;
        border: 1px solid $body-color;

        td,
        th {
            border: 1px solid $body-color;
            padding: 10px;
        }

        th {
            text-align: left;
        }
    }
    .privacy-title {
        font-size: 28px;
        line-height: 1.8;
        margin-bottom: 15px;
        font-weight: 100;
        text-transform: uppercase;

        @media screen and (max-width: $breakpoint-xs) {
            font-size: 20px;
            line-height: 1.4;
        }

        @media screen and (max-width: 374px) {
            font-size: 18px;
        }
    }

    .privacy-content,
    .privacy-content-list {
        p,
        li {
            font-size: $primary-text-size;
            line-height: 1.6;
            color: $privacy-text-color;
        }

        .link {
            font-size: $primary-text-size;
            line-height: 1.6;
            font-weight: 400;

            @media screen and (max-width: $breakpoint-sm) {
                word-break: break-all;
            }
        }

        .bold {
            font-weight: 700;
        }

        .space-right {
            margin-right: 4px;
        }
    }

    .privacy-subtitle {
        font-size: $primary-text-size;
        line-height: 1.6;
        color: $privacy-text-color;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 30px 25px;
  margin-top: auto;

  @media screen and (min-width: $breakpoint-sm) {
    padding-left: 80px;
    padding-right: 80px;
    margin-left: 80px;
  }

  .no-sidebar-menu & {
    @media screen and (min-width: $breakpoint-sm) {
      padding-left: 60px;
      padding-right: 60px;
      margin-left: 0;
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-left: 120px;
    }
  }

  &-logo {
    @media screen and (min-width: $breakpoint-sm) {
      margin-right: 20px;
    }

    .icon {
      width: 135px;
      height: 26px;
      color: $primary-logo-color;
      touch-action: none;

      &:hover {
        color: $primary-logo-color;
      }
    }

    span {
      display: block;
      opacity: 0.27;
      font-weight: 100;
      line-height: $footer-line-height;
      font-size: $lead-text-size;
    }
  }

  &-contact {
    .link {
      display: block;
      font-weight: 100;
      font-size: $lead-text-size;
      line-height: $footer-line-height;
      color: $body-color;
    }

    span {
      color: $footer-text-color;
      font-size: $lead-text-size;
      line-height: $footer-line-height;
      font-weight: 100;

      .link {
        display: inline;
        color: $link-color;
        font-size: $lead-text-size;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .footer {
    display: block;
  }
}

.theme-light {
  .footer-contact .link {
    color: $primary-bg;

    &:hover {
      color: $link-color;
    }
  }
}

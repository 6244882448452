.person {
    @media screen and (min-width: $breakpoint-sm) {
        display: flex;
    }

    &-link {
        display: inline-flex;
        font-size: $lead-text-size;
        color: $body-color;
        height: 50px;
        align-items: center;
        cursor: pointer;

        &:first-child {
            margin-right: 10px;
        }

        &-name {
            white-space: normal;
            display: inline-block;
            margin-left: 10px;
            text-align: right;
            max-width: 320px;
            text-overflow: ellipsis;
            overflow: hidden;
            order: 1;

            .theme-light & {
                color: $body-inverse-color;
            }

            @media screen and (min-width: $breakpoint-sm) {
                order: 0;
                margin-right: 10px;
            }
        }

        &-logo {
            display: inline-block;
            overflow: hidden;
            width: 50px;
            min-width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            vertical-align: middle;
            border-radius: 50%;
            background: $primary-bg;
            color: $primary-text-color;
        }

        .theme-primary & &-logo {
            background-color: $body-color;
            color: $body-inverse-color;
        }
    }
}

$breakpoint-tablet-landscape: 1023px;

.test-result-information {
  max-width: 500px;
  margin-right: 45px;
  order: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: $breakpoint-lg) {
    margin-right: 75px;
  }

  @media screen and (max-width: $breakpoint-tablet-landscape) {
    text-align: center;
    margin-right: 0;
  }

  .title {
    word-break: break-word;
    margin-bottom: 45px;
  }

  .result-text {
    font-size: $lead-text-size;
    font-weight: 300;
    line-height: $lead-line-height;
    margin-bottom: 45px;
    word-wrap: break-word;
    max-width: 420px;

    @media screen and (max-width: $breakpoint-tablet-landscape) {
      max-width: 100%;
    }
  }

  .result-link {
    display: block;
    color: $primary-bg;
    margin-bottom: 16px;
    cursor: pointer;

    &:hover {
      color: $icon-hover-color;
    }
  }

  .time-passed-message {
    font-size: 17;
  }

  &-modal {
    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 0;
      max-height: 95%;
      height: 95%;
    }
  }
  .new-design-button {
    width: 100%;
    &:nth-child(2) {
      margin-top: 10px;
    }
  }
  .buttons-row {
    display: flex;
    flex-direction: column;
  }
}

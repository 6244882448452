.react-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  padding: 20px 40px;
  transform: translate(-50%, -50%);
  border: none;
  max-height: 90%;
  overflow: auto;

  .paragraph {
    margin-top: 15px;
  }

  &:focus {
    outline: none;
  }

  &-size {
    &-big {
      max-width: 80%;
    }

    &-small {
      max-width: 500px;
    }
  }

  &-standard {
    background-color: transparentize($body-bg, 0.03);
    box-shadow: 0 0 25px 0 $primary-bg-hover, 0 0 2px 0 $primary-bg-hover,
      0 0 10px 0 $primary-bg-hover;
  }

  &-modified-darkened {
    background-color: $body-color;

    .details-modal-title {
      color: $body-inverse-color;
    }

    svg {
      fill: $text-sub-color;
      &:hover {
        fill: $body-inverse-color;
      }
    }
  }

  button {
    @media screen and (min-width: $breakpoint-sm) {
      min-width: 230px;
    }
  }
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-modal;

  &-standard {
    background-color: transparentize($body-bg, 0.5);
  }

  &-modified {
    background-color: transparentize($body-color, 0.05);
  }

  &-modified-darkened {
    background-color: transparentize($body-inverse-color, 0.5);
  }
}

@import '../../styles/colors';

.footer-landing {
    margin-top: auto;
    background: rgba(162, 134, 255, 0.2);
    padding-top: 20px;
    padding-bottom: 30px;

    @media (min-width: 768px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &__wrap {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            align-items: center;
            justify-content: flex-start;
        }
    }

    &__logo {
        margin-bottom: 28px;
        width: 100%;

        @media (min-width: 768px) {
            width: auto;
            margin-right: 16px;
            margin-bottom: 0;
        }

        @media (min-width: 1200px) {
            margin-left: 10px;
        }
    }

    &__info {
        font-size: 10px;
        line-height: 1.6;

        @media (min-width: 768px) {
            max-width: none;
            margin-right: auto;
        }

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__copy {
        color: #999;

        @media (min-width: 992px) {
            margin-right: 4px;
        }
    }

    &-menu {
        &__item {
            color: #fff;
            font-size: 12px;
            text-decoration: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            padding-left: 7px;
            transition: color 0.3s;
            cursor: pointer;

            @media (max-width: 767px) {
                display: block;
                margin-top: 4px;
            }

            & + & {
                margin-left: 8px;

                @media (max-width: 767px) {
                    margin-left: 0;
                }
            }

            &:before {
                content: '';
                width: 2px;
                height: 2px;
                background-color: #999;
                position: absolute;
                left: 0;
                top: 50%;
            }

            &:hover {
                color: $main-primary-light;
            }
        }
    }
}

.footer-social {
    display: flex;
    align-items: center;

    &__text {
        display: block;
        font-size: 14px;
        line-height: 18px;
        margin-right: 16px;

        @media (max-width: 1200px) {
            display: none;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 24px;

        svg {
            width: 100%;
            height: 100%;
        }

        & + & {
            margin-left: 16px;
        }

        path {
            transition: fill 0.3s;
        }

        &:hover path {
            fill: $main-primary-light;
        }
    }
}

.confirm-term-of-use {
  display: flex;
  margin-top: 25px;
  font-size: 14px;
  line-height: 1.43;

  &-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
  }

  .checkbox {
    margin-right: 15px;
  }

  .error {
    .checkbox_text::before {
      border-color: $danger-color;
    }
  }
}

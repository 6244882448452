@mixin get-submit-button-transition {
  transition: border-radius 0.3s ease, width 0.3s ease,
    background-color 0.3s ease, border 0.3s ease, padding 0.3s ease,
    border-color 0.3s ease;
}

.submit-button {
  display: inline-block;
  min-width: 200px;
  height: 52px;
  text-align: center;
  margin-right: 60px;

  @media screen and (max-width: $breakpoint-xs) {
    margin-right: 0;
  }

  &-loading {
    @include get-submit-button-transition();
    border-radius: 100px;
    width: 52px;
    height: 52px;
    border: 5px solid $primary-bg;
    border-bottom-color: transparent;
    animation: rotating 0.8s 0.3s linear infinite;
  }

  &-default {
    @include get-submit-button-transition();
    @extend .btn-primary;
    border-radius: 0px;
    height: 52px;
    width: 200px;
    padding: 0;
    border: 0px;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

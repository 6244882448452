.category {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $input-border-color;
  min-height: 69px;
  line-height: 69px;
  font-size: 14px;
  letter-spacing: 0.01em;

  .switch-input {
    vertical-align: middle;
  }

  &-icon {
    height: 32px;
    width: 32px;
    display: flex;
    background-color: $primary-text-color;
    border-radius: 100px;
    margin: 0 15px 0 20px;

    img {
      margin: auto;
      max-width: 22px;
      max-height: 22px;
    }
  }
}

.question-count {
  min-width: 44px;
  text-align: end;
}

.input-question-count {
  color: #666666;
  display: flex;
  margin-left: auto;
  align-items: center;
  .form-group {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .form-text-input.with-errors {
    margin-bottom: 0;
  }

  input {
    font-size: 14px;
    display: inline-block;
    max-width: 100px;
    max-height: 25px;
    text-align: right;
    margin: 0 5px 0 auto;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.eye-icon {
    position: absolute;
    height: 25px;
    width: 20px;
    top: 5px;
    left: 28px;

    @media screen and (max-width: $breakpoint-extra-lg) {
      height: 20px;
      width: 15px;
      top: 4px;
      left: 25px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 100%;
    }

    &::before {
      top: 0;
      left: 0;
      height: 12px;
      width: 12px;
      border: 3px solid #006d5c;

      @media screen and (max-width: $breakpoint-extra-lg) {
        height: 10px;
        width: 10px;
      }
    }

    &::after {
      height: 4px;
      width: 4px;
      background-color: $link-color;
      top: 7px;
      left: 7px;

      @media screen and (max-width: $breakpoint-extra-lg) {
        top: 6px;
        left: 6px;
      }
    }
  }

/* The container */
.radio-button {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: $input-text-size;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 300;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1.5px solid $input-border-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      display: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $link-color;
    }
  }
}

.attempt-grid-row {
  display: flex;
  margin: 0;
  justify-content: space-between;
  padding: 20px 20px 20px 0;
  border-top: 1px solid $grid-border-color;
  height: 42px;
  align-items: center;
  font-size: $primary-text-size;

  &:last-child {
    border-bottom: 1px solid $grid-border-color;
  }

  .link {
    color: $body-color;

    &:hover {
      color: $link-color;
    }
  }

  .cell {
    min-width: 150px;
    max-width: 150px;
  }

  .details {
    margin-top: 2px;
    color: $link-color;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
  }

  &-name {
    min-width: 380px;
    max-width: 380px;
    padding-right: 50px;
  }

  &-result {
    min-width: 250px;
    max-width: 250px;
  }
}

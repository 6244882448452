.update-admin-study-center {
  .info-buttons {
    margin-top: 40px;
  }

  .info-form {
    max-width: 600px;
    margin-bottom: 20px;
  }
}

@include font-face('Museo Sans', '../fonts/museosanscyrl-100', 100, normal, ttf);
@include font-face('Museo Sans', '../fonts/museosanscyrl-300', 300, normal, ttf);
@include font-face('Museo Sans', '../fonts/museosanscyrl-500', 500, normal, ttf);
@include font-face('Museo Sans', '../fonts/museosanscyrl-700', 700, normal, ttf);
@include font-face('Museo Sans', '../fonts/museosanscyrl-900', 900, normal, ttf);

@include font-face('KasperskySans', '../fonts/KasperskySans-Light', 300, normal, woff2);
@include font-face('KasperskySans', '../fonts/KasperskySans-Light', 300, normal, woff);
@include font-face('KasperskySans', '../fonts/KasperskySans-Regular', 400, normal, woff2);
@include font-face('KasperskySans', '../fonts/KasperskySans-Regular', 400, normal, woff);
